import {
    FLAG_NOTIFICATION_AS_ERROR,
    HIDE_NOTIFICATION,
    SHOW_NOTIFICATION,
    SHOW_TYPED_NOTIFICATION
} from 'src/actions/notifications';
import _omit from 'lodash/omit';
import uuid from 'lodash/uniqueId';

const createNotificationId = () => uuid();

export function notifications(state = {}, action) {
    const { payload, type } = action;
    switch (type) {
        case SHOW_NOTIFICATION: {
            const id = createNotificationId();
            return Object.assign({}, state, {
                [id]: {
                    id,
                    message: payload.message,
                    level: payload.level,
                    autoDismiss: payload.autoDismiss,
                    dismissible: payload.dismissible
                }
            });
        }
        case SHOW_TYPED_NOTIFICATION: {
            const { identifier } = payload;
            const id = (!identifier) ? createNotificationId() : identifier;
            return Object.assign({}, state, {
                [id]: {
                    id,
                    level: payload.level,
                    notificationType: payload.notificationType,
                    notificationProps: payload.notificationProps,
                    autoDismiss: payload.autoDismiss,
                    dismissible: payload.dismissible,
                    edited: false
                }
            });
        }
        case HIDE_NOTIFICATION: {
            return _omit(state, payload.id);
        }
        case FLAG_NOTIFICATION_AS_ERROR: {
            const {
                id
            } = payload;
            return Object.assign({}, state, {
                [id]: Object.assign({}, state[id], {
                    level: 'error'
                })
            });
        }
        default:
            return state;
    }
}
