export const FACEBOOK_AUTH_USER_CONNECT_REQUEST = 'FACEBOOK_AUTH_USER_CONNECT_REQUEST';
export const FACEBOOK_AUTH_USER_CONNECT_SUCCESS = 'FACEBOOK_AUTH_USER_CONNECT_SUCCESS';
export const FACEBOOK_AUTH_USER_CONNECT_ERROR = 'FACEBOOK_AUTH_USER_CONNECT_ERROR';

export const FACEBOOK_AUTH_USER_FORCE_CONNECT_REQUEST = 'FACEBOOK_AUTH_USER_FORCE_CONNECT_REQUEST';
export const FACEBOOK_AUTH_USER_FORCE_CONNECT_SUCCESS = 'FACEBOOK_AUTH_USER_FORCE_CONNECT_SUCCESS';
export const FACEBOOK_AUTH_USER_FORCE_CONNECT_ERROR = 'FACEBOOK_AUTH_USER_FORCE_CONNECT_ERROR';

export const FACEBOOK_AUTH_USER_EXTERNAL_CONNECT_REQUEST = 'FACEBOOK_AUTH_USER_EXTERNAL_CONNECT_REQUEST';
export const FACEBOOK_AUTH_USER_EXTERNAL_CONNECT_SUCCESS = 'FACEBOOK_AUTH_USER_EXTERNAL_CONNECT_SUCCESS';
export const FACEBOOK_AUTH_USER_EXTERNAL_CONNECT_ERROR = 'FACEBOOK_AUTH_USER_EXTERNAL_CONNECT_ERROR';

export const FACEBOOK_AUTH_USER_EXTERNAL_FORCE_CONNECT_REQUEST = 'FACEBOOK_AUTH_USER_EXTERNAL_FORCE_CONNECT_REQUEST';
export const FACEBOOK_AUTH_USER_EXTERNAL_FORCE_CONNECT_SUCCESS = 'FACEBOOK_AUTH_USER_EXTERNAL_FORCE_CONNECT_SUCCESS';
export const FACEBOOK_AUTH_USER_EXTERNAL_FORCE_CONNECT_ERROR = 'FACEBOOK_AUTH_USER_EXTERNAL_FORCE_CONNECT_ERROR';

export const facebookAuthUserConnectRequest = (identifier, authTransactionId, facebookAuthReq, instagramAuthReq, metaAuthReq) => ({
    type: FACEBOOK_AUTH_USER_CONNECT_REQUEST,
    payload: {
        identifier,
        authTransactionId,
        facebookAuthReq,
        instagramAuthReq,
        metaAuthReq
    }
});

export const facebookAuthUserConnectSuccess = (
    identifier,
    authTransaction,
    authTransactionRequirements,
    data = {}
) => ({
    type: FACEBOOK_AUTH_USER_CONNECT_SUCCESS,
    payload: {
        identifier,
        authTransaction,
        authTransactionRequirements,
        data
    }
});

export const facebookAuthUserConnectError = (identifier, error) => ({
    type: FACEBOOK_AUTH_USER_CONNECT_ERROR,
    payload: { identifier, error }
});

export const facebookAuthUserForceConnectRequest = (identifier, authTransactionId, authTransactionRequirementIds, shortLiveAccessToken, profileIds, adAccountIds) => ({
    type: FACEBOOK_AUTH_USER_FORCE_CONNECT_REQUEST,
    payload: {
        identifier,
        authTransactionId,
        authTransactionRequirementIds,
        shortLiveAccessToken,
        profileIds,
        adAccountIds
    }
});

export const facebookAuthUserForceConnectSuccess = (
    identifier,
    authTransaction,
    authTransactionRequirements,
    data = {}
) => ({
    type: FACEBOOK_AUTH_USER_FORCE_CONNECT_SUCCESS,
    payload: {
        identifier,
        authTransaction,
        authTransactionRequirements,
        data
    }
});

export const facebookAuthUserForceConnectError = (identifier, error) => ({
    type: FACEBOOK_AUTH_USER_FORCE_CONNECT_ERROR,
    payload: { identifier, error }
});

export const facebookAuthUserExternalConnectRequest = (hash, identifier, authTransactionId, facebookAuthReq, instagramAuthReq, metaAuthReq) => ({
    type: FACEBOOK_AUTH_USER_EXTERNAL_CONNECT_REQUEST,
    payload: {
        hash,
        identifier,
        authTransactionId,
        facebookAuthReq,
        instagramAuthReq,
        metaAuthReq
    }
});

export const facebookAuthUserExternalConnectSuccess = (
    identifier,
    authTransaction,
    authTransactionRequirements,
    data = {}
) => ({
    type: FACEBOOK_AUTH_USER_EXTERNAL_CONNECT_SUCCESS,
    payload: {
        identifier,
        authTransaction,
        authTransactionRequirements,
        data
    }
});

export const facebookAuthUserExternalConnectError = (identifier, error) => ({
    type: FACEBOOK_AUTH_USER_EXTERNAL_CONNECT_ERROR,
    payload: { identifier, error }
});

export const facebookAuthUserExternalForceConnectRequest = (hash, identifier, authTransactionId, authTransactionRequirementIds, shortLiveAccessToken, profileIds, adAccountIds) => ({
    type: FACEBOOK_AUTH_USER_EXTERNAL_FORCE_CONNECT_REQUEST,
    payload: {
        hash,
        identifier,
        authTransactionId,
        authTransactionRequirementIds,
        shortLiveAccessToken,
        profileIds,
        adAccountIds
    }
});

export const facebookAuthUserExternalForceConnectSuccess = (
    identifier,
    authTransaction,
    authTransactionRequirements,
    data = {}
) => ({
    type: FACEBOOK_AUTH_USER_EXTERNAL_FORCE_CONNECT_SUCCESS,
    payload: {
        identifier,
        authTransaction,
        authTransactionRequirements,
        data
    }
});

export const facebookAuthUserExternalForceConnectError = (identifier, error) => ({
    type: FACEBOOK_AUTH_USER_EXTERNAL_FORCE_CONNECT_ERROR,
    payload: { identifier, error }
});
