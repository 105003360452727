import Notification from 'src/components/notifications/layout/Notification';
import Feedback from 'src/components/feedback/Feedback';
import PropTypes from 'prop-types';
import React from 'react';

const GroupAddSuccess = (props) => {
    const { groupName } = props;
    return (
        <Notification>
            <Feedback
              type="success"
              responsive={false}
              content={`The group "${groupName}" was successfully added.`}
            />
        </Notification>
    );
};

GroupAddSuccess.propTypes = {
    groupName: PropTypes.string.isRequired
};

export default GroupAddSuccess;
