export const SHARED_DASHBOARD_BOOTSTRAP_REQUEST = 'SHARED_DASHBOARD_BOOTSTRAP_REQUEST';
export const SHARED_DASHBOARD_BOOTSTRAP_SUCCESS = 'SHARED_DASHBOARD_BOOTSTRAP_SUCCESS';
export const SHARED_DASHBOARD_BOOTSTRAP_ERROR = 'SHARED_DASHBOARD_BOOTSTRAP_ERROR';

export const sharedDashboardBootstrapRequest = (hash) => ({
    type: SHARED_DASHBOARD_BOOTSTRAP_REQUEST,
    payload: {
        hash
    }
});

export const sharedDashboardBootstrapSuccess = (
    hash,
    accessToken,
    dashboardId,
    dashboards,
    metrics,
    accountOptions,
    filter,
    profiles,
    adAccounts,
    groups,
    visualizations,
    dashboardMetrics,
    clientSetting,
    postTags,
    adAccountCampaigns,
    faceliftTags,
    faceliftCampaigns
) => ({
    type: SHARED_DASHBOARD_BOOTSTRAP_SUCCESS,
    payload: {
        accessToken,
        dashboardId,
        dashboards,
        hash,
        metrics,
        accountOptions,
        filter,
        profiles,
        groups,
        visualizations,
        dashboardMetrics,
        clientSetting,
        postTags,
        adAccounts,
        adAccountCampaigns,
        faceliftTags,
        faceliftCampaigns
    }
});

export const sharedDashboardBootstrapError = (error, hash) => (
    {
        type: SHARED_DASHBOARD_BOOTSTRAP_ERROR,
        payload: {
            hash,
            error
        }
    }
);
