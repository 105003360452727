import {
    call,
    put,
    takeEvery,
} from 'redux-saga/effects';
import {
    getBaseUrl,
    getUniqueUseCaseIdsWithAuthTransactionReqIds,
    handleAuthorizedServerRequest,
    getScopesByUseCases,
    popUpWindowWithCallbackUrlAction
} from 'src/sagas/utils';
import { parseAuthTransaction, parseAuthTransactionsRequirements } from 'src/parsers';
import {
    SNAPCHAT_AUTH_USER_CONNECT_REQUEST,
    SNAPCHAT_AUTH_USER_EXTERNAL_CONNECT_REQUEST,
    SNAPCHAT_AUTH_USER_EXTERNAL_FORCE_CONNECT_REQUEST,
    SNAPCHAT_AUTH_USER_FORCE_CONNECT_REQUEST,
    snapchatAuthUserConnectError,
    snapchatAuthUserConnectSuccess,
    snapchatAuthUserExternalConnectError,
    snapchatAuthUserExternalConnectSuccess,
    snapchatAuthUserExternalForceConnectError,
    snapchatAuthUserExternalForceConnectSuccess,
    snapchatAuthUserForceConnectError,
    snapchatAuthUserForceConnectSuccess
} from 'src/actions/snapchatAuthUsers';
import {
    encodeUrlParams, snapchatClientId, snapchatOauthUrl, snapchatResponseType
} from 'src/config';
import _parseInt from 'lodash/parseInt';
import createServerRequest from 'src/requestHandling/createServerRequest';
import { modalsShowSnapchatAuthUserInAnotherSpaceAuthTransactionWarning } from 'src/actions/overlays';
import { showAuthenticatedProfilesByAuthUserSuccessOrWarningNotification } from 'src/actions/notifications';
import { getSnapchatAuthenticationCallbackUrl } from 'src/utils/authUsers';

const generateSnapchatUrl = (finalScopes, endpoint) => {
    const params = {
        redirect_uri: `${getBaseUrl()}${endpoint}`,
        client_id: snapchatClientId,
        response_type: snapchatResponseType,
        scope: finalScopes
    };
    return `${snapchatOauthUrl}?${encodeUrlParams(params)}`;
};

function* snapchatAuthUserConnectRequest(action) {
    const { payload } = action;
    const {
        identifier,
        authTransactionId,
        authTransactionRequirementsByProfile,
    } = payload;

    const { useCaseIds, authTransactionRequirementIds } = getUniqueUseCaseIdsWithAuthTransactionReqIds(authTransactionRequirementsByProfile);
    const finalScopes = yield call(getScopesByUseCases, useCaseIds, 'snapchat');
    const url = getSnapchatAuthenticationCallbackUrl(finalScopes);
    try {
        const { response, serverError } = yield call(popUpWindowWithCallbackUrlAction, url, 'Snapchat');
        if (response) {
            const authorizedServerRequest = createServerRequest({
                code: response.code,
                authTransactionId,
                authTransactionRequirementIds: JSON.stringify(authTransactionRequirementIds)
            });
            const { response: authorizedResponse, serverError: authorizedServerError } = yield handleAuthorizedServerRequest(authorizedServerRequest, '/client-index/finish-snapchat-auth-user-connection-callback-url');
            if (authorizedResponse) {
                const { isAuthUserInAnotherSpace } = authorizedResponse.jsonData;
                if (isAuthUserInAnotherSpace) {
                    const {
                        accessToken,
                        refreshAccessToken,
                        expiresIn,
                        platformUserId,
                        displayName
                    } = authorizedResponse.jsonData;
                    yield put(snapchatAuthUserConnectSuccess(identifier, null, []));
                    yield put(modalsShowSnapchatAuthUserInAnotherSpaceAuthTransactionWarning(
                        identifier, authTransactionId, authTransactionRequirementIds, accessToken,
                        refreshAccessToken, expiresIn, platformUserId, displayName, Object.keys(authTransactionRequirementsByProfile), false
                    ));
                } else {
                    const {
                        authTransaction,
                        connectedProfileIds
                    } = authorizedResponse.jsonData;
                    const parsedAuthTransaction = parseAuthTransaction(authTransaction);
                    const parsedAuthTransactionRequirements = parseAuthTransactionsRequirements(authTransaction);
                    yield put(snapchatAuthUserConnectSuccess(
                        identifier,
                        parsedAuthTransaction,
                        parsedAuthTransactionRequirements
                    ));
                    yield put(showAuthenticatedProfilesByAuthUserSuccessOrWarningNotification(connectedProfileIds.map((id) => `${id}`), Object.keys(authTransactionRequirementsByProfile)));
                }
            }
            if (authorizedServerError) {
                throw authorizedServerError;
            }
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(snapchatAuthUserConnectError(identifier, applicationError.message));
    }
}

function* snapchatAuthUserForceConnectRequest(action) {
    const { payload } = action;
    const {
        identifier, authTransactionId, authTransactionRequirementIds, accessToken,
        refreshAccessToken, expiresIn, platformUserId, displayName, profileIds
    } = payload;

    const params = {
        authTransactionRequirementIds: JSON.stringify(authTransactionRequirementIds),
        authTransactionId: _parseInt(authTransactionId),
        accessToken,
        refreshAccessToken,
        expiresIn,
        platformUserId,
        displayName
    };

    try {
        const serverRequest = createServerRequest(params);
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/handle-snapchat-auth-user-force-connection');
        if (response) {
            const { authTransaction, connectedProfileIds } = response.jsonData;
            const parsedAuthTransaction = parseAuthTransaction(authTransaction);
            const parsedAuthTransactionRequirements = parseAuthTransactionsRequirements(authTransaction);

            yield put(snapchatAuthUserForceConnectSuccess(
                identifier,
                parsedAuthTransaction,
                parsedAuthTransactionRequirements
            ));
            yield put(showAuthenticatedProfilesByAuthUserSuccessOrWarningNotification(connectedProfileIds.map((id) => `${id}`), profileIds));
        }

        if (serverError) {
            throw serverError;
        }
    } catch (applicationServer) {
        yield put(snapchatAuthUserForceConnectError(identifier, applicationServer.message));
    }
}

function* snapchatAuthUserExternalConnectRequest(action) {
    const { payload } = action;
    const {
        hash,
        identifier,
        authTransactionId,
        authTransactionRequirementsByProfile
    } = payload;

    const { useCaseIds, authTransactionRequirementIds } = getUniqueUseCaseIdsWithAuthTransactionReqIds(authTransactionRequirementsByProfile);
    const finalScopes = yield call(getScopesByUseCases, useCaseIds, 'snapchat');
    const url = generateSnapchatUrl(finalScopes, '/client-index/handle-snapchat-auth-user-connection-callback-url');
    try {
        const { response, serverError } = yield call(popUpWindowWithCallbackUrlAction, url, 'Snapchat');
        if (response) {
            const authorizedServerRequest = createServerRequest({
                code: response.code,
                authTransactionId,
                authTransactionRequirementIds: JSON.stringify(authTransactionRequirementIds),
                hash
            });
            const { response: authorizedResponse, serverError: authorizedServerError } = yield handleAuthorizedServerRequest(authorizedServerRequest, '/client-public/finish-snapchat-external-auth-user-connection-callback-url');
            if (authorizedResponse) {
                const { isAuthUserInAnotherSpace } = authorizedResponse.jsonData;
                if (isAuthUserInAnotherSpace) {
                    const {
                        accessToken,
                        refreshAccessToken,
                        expiresIn,
                        platformUserId,
                        displayName
                    } = authorizedResponse.jsonData;
                    yield put(snapchatAuthUserExternalConnectSuccess(identifier, null, []));
                    yield put(modalsShowSnapchatAuthUserInAnotherSpaceAuthTransactionWarning(
                        identifier, authTransactionId, authTransactionRequirementIds, accessToken,
                        refreshAccessToken, expiresIn, platformUserId, displayName, Object.keys(authTransactionRequirementsByProfile), true
                    ));
                } else {
                    const { authTransaction, connectedProfileIds } = authorizedResponse.jsonData;
                    const parsedAuthTransaction = parseAuthTransaction(authTransaction);
                    const parsedAuthTransactionRequirements = parseAuthTransactionsRequirements(authTransaction);
                    yield put(snapchatAuthUserExternalConnectSuccess(
                        identifier,
                        parsedAuthTransaction,
                        parsedAuthTransactionRequirements
                    ));
                    yield put(showAuthenticatedProfilesByAuthUserSuccessOrWarningNotification(connectedProfileIds.map((id) => `${id}`), Object.keys(authTransactionRequirementsByProfile)));
                }
            }
            if (authorizedServerError) {
                throw authorizedServerError;
            }
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(snapchatAuthUserExternalConnectError(identifier, applicationError.message));
    }
}

function* snapchatAuthUserExternalForceConnectRequest(action) {
    const { payload } = action;
    const {
        hash, identifier, authTransactionId, authTransactionRequirementIds, accessToken,
        refreshAccessToken, expiresIn, platformUserId, displayName, profileIds
    } = payload;

    const params = {
        hash,
        authTransactionRequirementIds: JSON.stringify(authTransactionRequirementIds),
        authTransactionId: _parseInt(authTransactionId),
        accessToken,
        refreshAccessToken,
        expiresIn,
        platformUserId,
        displayName
    };

    try {
        const serverRequest = createServerRequest(params);
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-public/handle-external-snapchat-auth-user-force-connection');
        if (response) {
            const { authTransaction, connectedProfileIds } = response.jsonData;
            const parsedAuthTransaction = parseAuthTransaction(authTransaction);
            const parsedAuthTransactionRequirements = parseAuthTransactionsRequirements(authTransaction);

            yield put(snapchatAuthUserExternalForceConnectSuccess(
                identifier,
                parsedAuthTransaction,
                parsedAuthTransactionRequirements
            ));
            yield put(showAuthenticatedProfilesByAuthUserSuccessOrWarningNotification(connectedProfileIds.map((id) => `${id}`), profileIds));
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationServer) {
        yield put(snapchatAuthUserExternalForceConnectError(identifier, applicationServer.message));
    }
}

export default function* snapchatAuthUsersSagas() {
    yield takeEvery(SNAPCHAT_AUTH_USER_CONNECT_REQUEST, snapchatAuthUserConnectRequest);
    yield takeEvery(SNAPCHAT_AUTH_USER_FORCE_CONNECT_REQUEST, snapchatAuthUserForceConnectRequest);
    yield takeEvery(SNAPCHAT_AUTH_USER_EXTERNAL_CONNECT_REQUEST, snapchatAuthUserExternalConnectRequest);
    yield takeEvery(SNAPCHAT_AUTH_USER_EXTERNAL_FORCE_CONNECT_REQUEST, snapchatAuthUserExternalForceConnectRequest);
}
