import * as customPropTypes from 'src/customPropTypes';
import { connect } from 'react-redux';
import { makeSelectOnlyProfilesByIds } from 'src/selectors/profiles';
import { makeSelectOnlyAdAccountByIds } from 'src/selectors/adAccounts';
import Notification from 'src/components/notifications/layout/Notification';
import Feedback from 'src/components/feedback/Feedback';
import PropTypes from 'prop-types';
import React from 'react';
import _difference from 'lodash/difference';

const getFailedAccountIds = (allIds, connectedAccountIds) => _difference(allIds, connectedAccountIds);

const generateFailedSentence = (accounts) => {
    const accountNames = accounts.map(({ defaultName }) => defaultName);
    const firstThree = accountNames.slice(0, 3).join(', ');
    const remaining = accountNames.slice(3);

    let sentence = `${firstThree}`;
    if (remaining.length > 0) {
        sentence = `${firstThree} and ${remaining.length} other${remaining.length > 1 ? 's' : ''}.`;
    }
    return `${sentence} could not connect, please try again.`;
};

const AuthenticatedProfilesByAuthUserSuccessOrWarning = (props) => {
    const {
        connectedProfiles, connectedAdAccounts, failedProfiles, failedAdAccounts
    } = props;
    const connectedAccounts = connectedProfiles.concat(connectedAdAccounts);
    const failedAccounts = failedProfiles.concat(failedAdAccounts);
    let type = 'error';
    let sentence = '';
    // all connected
    if (connectedAccounts.length > 0 && failedAccounts.length === 0) {
        sentence = `Account${connectedAccounts.length > 1 ? 's' : ''} connected successfully.`;
        type = 'success';
    }

    // partially connected
    if (connectedAccounts.length > 0 && failedAccounts.length > 0) {
        sentence = generateFailedSentence(failedAccounts);
        type = 'warning';
    }

    // all failed
    if (connectedAccounts.length === 0 && failedAccounts.length > 0) {
        sentence = `Account${failedAccounts.length > 1 ? 's' : ''} could not connect, please try again.`;
        type = 'error';
    }
    return (
        <Notification>
            <Feedback
              type={type}
              responsive={false}
              content={sentence}
            />
        </Notification>
    );
};

AuthenticatedProfilesByAuthUserSuccessOrWarning.propTypes = {
    allProfileIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    connectedProfileIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    connectedProfiles: customPropTypes.profiles.isRequired,
    failedProfiles: customPropTypes.profiles.isRequired,
    connectedAdAccounts: customPropTypes.adAccounts.isRequired,
    failedAdAccounts: customPropTypes.adAccounts.isRequired
};

const makeMapStateToProps = () => {
    const selectOnlyProfilesByIds = makeSelectOnlyProfilesByIds();
    const selectOnlyAdAccountByIds = makeSelectOnlyAdAccountByIds();
    return (state, ownProps) => {
        const {
            allProfileIds,
            connectedProfileIds,
            allAdAccountIds,
            connectedAdAccountIds
        } = ownProps;
        return {
            connectedProfiles: selectOnlyProfilesByIds(state, connectedProfileIds),
            failedProfiles: selectOnlyProfilesByIds(state, getFailedAccountIds(allProfileIds, connectedProfileIds)),
            connectedAdAccounts: selectOnlyAdAccountByIds(state, connectedAdAccountIds),
            failedAdAccounts: selectOnlyAdAccountByIds(state, getFailedAccountIds(allAdAccountIds, connectedAdAccountIds))
        };
    };
};

export default connect(makeMapStateToProps)(AuthenticatedProfilesByAuthUserSuccessOrWarning);
