import Feedback from 'src/components/feedback/Feedback';
import Notification from 'src/components/notifications/layout/Notification';
import PropTypes from 'prop-types';
import React from 'react';

const FolderAddSuccess = (props) => {
    const { folderName } = props;
    return (
        <Notification>
            <Feedback
              type="success"
              responsive={false}
              content={`The folder "${folderName}" was successfully added.`}
            />
        </Notification>
    );
};

FolderAddSuccess.propTypes = {
    folderName: PropTypes.string.isRequired,
};

export default FolderAddSuccess;
