import FeedbackLinkGroup from 'src/components/feedback/FeedbackLinkGroup';
import FeedbackLinkPlain from 'src/components/feedback/FeedbackLinkPlain';
import * as customPropTypes from 'src/customPropTypes';
import { connect } from 'react-redux';
import Feedback from 'src/components/feedback/Feedback';
import { makeSelectDashboardsByIds } from 'src/selectors/dashboards';
import { makeSelectFoldersByFolderIds } from 'src/selectors/folders';
import Notification from 'src/components/notifications/layout/Notification';
import React from 'react';
import PropTypes from 'prop-types';
import { selectFolderTypeById } from 'src/selectors/ownership';
import { getPathForFolderOfType } from 'src/utils/routes';
import { push } from 'react-router-redux';

const FoldersAndDashboardMoveSuccessOrWarning = (props) => {
    const {
        failedFolders, succeededFolders, failedDashboards, succeededDashboards, routerPushAction, pathToNewFolder
    } = props;
    let failedContent = '';
    let successContent = '';
    const successedFolderNames = succeededFolders.map((folder) => folder.name);
    const succededDashboardNames = succeededDashboards.map((dashboard) => dashboard.name);

    let type = '';
    const sumOfIds = failedFolders.length + succeededFolders.length + failedDashboards.length + succeededDashboards.length;
    const sumOfAllErrorIds = failedFolders.length + failedDashboards.length;

    if (sumOfIds !== sumOfAllErrorIds) {
        successContent = (
            <span>
                {
                    successedFolderNames.length > 0
                        && <span>{successedFolderNames.length} folder{successedFolderNames.length > 1 ? 's' : ''} </span>
                }
                {
                    successedFolderNames.length > 0 && succededDashboardNames.length > 0
                        && <span> and </span>
                }
                {
                    succededDashboardNames.length > 0
                        && <span>{succededDashboardNames.length} dashboard{succededDashboardNames.length > 1 ? 's' : ''} </span>
                }
                {
                    (successedFolderNames.length + succededDashboardNames.length) > 1 ? 'were ' : 'was '
                }
                successfully moved.<br />
            </span>
        );
    }

    if (sumOfAllErrorIds > 0) {
        const folderNames = failedFolders.map((folder) => folder.name);
        const dashboardNames = failedDashboards.map((dashboard) => dashboard.name);
        failedContent = (
            <span>
                We were unable to move the following folders and/or dashboards. Please try again in a few moments.<br /><br />
                {
                    folderNames.length > 0
                        && (
                        <span>
                            Folders: <b>{folderNames.join(', ')}</b>
                            <br />
                        </span>
                        )
                }
                {
                    dashboardNames.length > 0
                        && (
                        <span>
                            Dashboards: <b>{dashboardNames.join(', ')}</b>
                            <br />
                        </span>
                        )
                }
            </span>
        );
    }

    if (sumOfAllErrorIds === 0) {
        type = 'success';
    } else if (sumOfIds === sumOfAllErrorIds) {
        type = 'error';
    } else {
        type = 'warning';
    }

    const links = successContent ? (
        <FeedbackLinkGroup>
            <FeedbackLinkPlain onClick={() => { routerPushAction(pathToNewFolder); }}>Go to new location</FeedbackLinkPlain>
        </FeedbackLinkGroup>
    ) : null;

    return (
        <Notification>
            <Feedback
              type={type}
              responsive={false}
              link={links}
              content={(
                  <div>
                      {successContent}
                      {failedContent}
                  </div>
              )}
            />
        </Notification>
    );
};

FoldersAndDashboardMoveSuccessOrWarning.propTypes = {
    failedFolders: customPropTypes.folders.isRequired,
    succeededFolders: customPropTypes.folders.isRequired,
    failedDashboards: customPropTypes.dashboards.isRequired,
    succeededDashboards: customPropTypes.dashboards.isRequired,
    toFolderId: PropTypes.string.isRequired,
    pathToNewFolder: PropTypes.string.isRequired,
    routerPushAction: PropTypes.func.isRequired
};

const makeMapStateToProps = () => {
    const selectFailedFoldersByFolderIds = makeSelectFoldersByFolderIds();
    const selectSucceededFoldersByFolderIds = makeSelectFoldersByFolderIds();
    const selectFailedDashboardsByIds = makeSelectDashboardsByIds();
    const selectSucceededDashboardsByIds = makeSelectDashboardsByIds();
    return (state, ownProps) => {
        const { foldersResponses, dashboardsResponses, toFolderId } = ownProps;
        const successFolderIds = foldersResponses.success.map((folderResponse) => {
            const { movedFolder } = folderResponse;
            return movedFolder.folder.id;
        });
        const successDashboardIds = dashboardsResponses.success.map((dashbardResponse) => {
            const { movedDashboard } = dashbardResponse;
            return movedDashboard.dashboard.id;
        });

        const toFolderType = selectFolderTypeById(state, toFolderId);

        return {
            failedFolders: selectFailedFoldersByFolderIds(state, foldersResponses.errors),
            succeededFolders: selectSucceededFoldersByFolderIds(state, successFolderIds),
            failedDashboards: selectFailedDashboardsByIds(state, dashboardsResponses.errors),
            succeededDashboards: selectSucceededDashboardsByIds(state, successDashboardIds),
            pathToNewFolder: getPathForFolderOfType(toFolderId, toFolderType)
        };
    };
};

export default connect(makeMapStateToProps, {
    routerPushAction: push
})(FoldersAndDashboardMoveSuccessOrWarning);
