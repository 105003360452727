import Body from 'src/components/Body';
import EmptyInfoScreen from 'src/components/EmptyInfoScreen';
import Footer from 'src/components/Footer';
import pageNotFoundImage from 'src/resources/illustrations/key-metrics-web.png';
import React from 'react';
import ScrollView from 'src/components/ScrollView';
import styles from 'src/stylesheets/views/pageNotFound.scss';

const PageNotFound = () => (
    <ScrollView
      className={styles.view}
      dataViewName="page-not-found"
    >
        <Body className={styles.body}>
            <div className={styles.wrapper}>
                <EmptyInfoScreen
                  image={pageNotFoundImage}
                  title="Page not found"
                  message="The page you tried to access does not exist."
                />
            </div>
        </Body>
        <Footer />
    </ScrollView>
);

export default PageNotFound;
