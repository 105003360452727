import {
    put, takeEvery, select, call
} from 'redux-saga/effects';
import {
    CLOSE_AUTH_TRANSACTION_REQUEST,
    CLOSE_EXTERNAL_AUTH_TRANSACTION_REQUEST,
    closeAuthTransactionError,
    closeAuthTransactionSuccess,
    closeExternalAuthTransactionError,
    closeExternalAuthTransactionSuccess,
    CREATE_AUTH_TRANSACTION_REQUEST,
    CREATE_BASIC_AUTH_TRANSACTION_REQUEST,
    CREATE_ONBOARDING_BASIC_AUTH_TRANSACTION_REQUEST,
    createAuthTransactionError,
    createAuthTransactionSuccess,
    createBasicAuthTransactionSuccess,
    createOnboardingBasicAuthTransactionSuccess,
    createOnboardingBasicAuthTransactionError,
    GET_AUTH_TRANSACTION_BY_HASH_REQUEST,
    getAuthTransactionByHashError,
    getAuthTransactionByHashSuccess,
    UPDATE_AUTH_TRANSACTION_NAME_REQUEST,
    updateAuthTransactionNameError,
    updateAuthTransactionNameSuccess,
    AUTH_TRANSACTION_REOPEN_REQUEST,
    authTransactionReopenError,
    authTransactionReopenSuccess,
    loadAuthTransactionsRequest,
    loadAuthTransactionsError,
    loadAuthTransactionsSuccess
} from 'src/actions/authTransactions';
import {
    modalsHideAddUpdateAuthUsers,
    modalsHideAuthTransactionClose,
    modalsHideCreateAuthTransactionRequirements,
    modalsShowAddUpdateAuthUsers,
    modalsHideAuthTransactionDetail
} from 'src/actions/overlays';
import {
    parseAuthTransaction,
    parseAuthTransactionsRequirements,
    parsePlatformPermissions,
    parseProfiles,
    parseAdAccounts,
    parseUseCases,
    parseAuthTransactionEntities
} from 'src/parsers';
import createServerRequest from 'src/requestHandling/createServerRequest';
import { getAuthTransactionRequirementsByProfile } from 'src/utils/accountInsights';
import { handleAuthorizedServerRequest } from 'src/sagas/utils';
import { showNotification } from 'src/actions/notifications';
import { getAuthTransactionName } from 'src/utils/authTransactionUtils';
import { getAccountOptions } from 'src/selectors/loggedInUser';
import { SubmissionError } from 'redux-form';
import _parseInt from 'lodash/parseInt';
import { PROFILE_DELETE_SUCCESS, PROFILE_FORCE_DELETE_SUCCESS } from 'src/actions/profiles';
import { adAccountDeleteSuccess, adAccountForceDeleteSuccess } from 'src/actions/adAccounts';
import { USER_LOGGED_IN } from 'src/actions/loggedInUser';

function* createAuthTransactionRequest(action) {
    const { payload } = action;
    const { formValues } = payload;
    const { profilesUseCases, adAccountsUseCases } = getAuthTransactionRequirementsByProfile(formValues);
    const accountOption = yield select(getAccountOptions);
    const transactionName = getAuthTransactionName(accountOption);
    const params = {
        transactionName,
        profileTransactionRequirements: JSON.stringify(profilesUseCases),
        adAccountTransactionRequirements: JSON.stringify(adAccountsUseCases)
    };

    try {
        const serverRequest = createServerRequest(params);
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/create-auth-transaction');
        if (response) {
            const { authTransaction } = response.jsonData;
            const parsedAuthTransaction = parseAuthTransaction(authTransaction);
            const parsedAuthTransactionRequirements = parseAuthTransactionsRequirements(authTransaction);
            yield put(createAuthTransactionSuccess(parsedAuthTransaction, parsedAuthTransactionRequirements));
            yield put(modalsShowAddUpdateAuthUsers(parsedAuthTransaction.id));
            yield put(modalsHideCreateAuthTransactionRequirements());
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(showNotification(applicationError.message, 'error'));
        yield put(createAuthTransactionError(new SubmissionError({ _error: applicationError })));
    }
}

function* getAuthRequestParams(basicAuthTransactionRequirements) {
    const accountOption = yield select(getAccountOptions);
    const transactionName = getAuthTransactionName(accountOption);
    return {
        transactionName,
        transactionRequirements: JSON.stringify(basicAuthTransactionRequirements)
    };
}

function* createBasicAuthTransactionRequest(action) {
    const { payload } = action;
    const { basicAuthTransactionRequirements } = payload;
    const params = yield call(getAuthRequestParams, basicAuthTransactionRequirements);

    try {
        const serverRequest = createServerRequest(params);
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/create-auth-transaction');
        if (response) {
            const { authTransaction } = response.jsonData;
            const parsedAuthTransaction = parseAuthTransaction(authTransaction);
            const parsedAuthTransactionRequirements = parseAuthTransactionsRequirements(authTransaction);
            yield put(createBasicAuthTransactionSuccess(parsedAuthTransaction, parsedAuthTransactionRequirements));
            yield put(modalsShowAddUpdateAuthUsers(parsedAuthTransaction.id));
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(showNotification(applicationError.message, 'error'));
        yield put(createAuthTransactionError(new SubmissionError({ _error: applicationError })));
    }
}

function* createOnboardingBasicAuthTransactionRequest(action) {
    const { payload } = action;
    const { basicAuthTransactionRequirements } = payload;
    const params = yield call(getAuthRequestParams, basicAuthTransactionRequirements);
    try {
        const serverRequest = createServerRequest(params);
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/create-auth-transaction');
        if (response) {
            const { authTransaction } = response.jsonData;
            const parsedAuthTransaction = parseAuthTransaction(authTransaction);
            const parsedAuthTransactionRequirements = parseAuthTransactionsRequirements(authTransaction);
            yield put(createOnboardingBasicAuthTransactionSuccess(parsedAuthTransaction, parsedAuthTransactionRequirements));
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(showNotification(applicationError.message, 'error'));
        yield put(createOnboardingBasicAuthTransactionError(new SubmissionError({ _error: applicationError })));
    }
}

function* closeAuthTransactionRequest(action) {
    const { payload } = action;
    const { id } = payload;

    try {
        const serverRequest = createServerRequest({ authTransactionId: _parseInt(id) });
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/close-auth-transaction');
        if (response) {
            const { authTransaction } = response.jsonData;
            const parsedAuthTransaction = parseAuthTransaction(authTransaction);
            yield put(closeAuthTransactionSuccess(id, parsedAuthTransaction));
            yield put(modalsHideAuthTransactionClose());
            yield put(modalsHideAddUpdateAuthUsers(id));
        }

        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(showNotification(applicationError.message, 'error'));
        yield put(closeAuthTransactionError(id, applicationError.message));
    }
}

function* reopenAuthTransactionRequest(action) {
    const { payload } = action;
    const { id } = payload;

    try {
        const serverRequest = createServerRequest({ authTransactionId: _parseInt(id) });
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/re-open-auth-transaction');
        if (response) {
            const { authTransaction } = response.jsonData;
            const parsedAuthTransaction = parseAuthTransaction(authTransaction);
            yield put(authTransactionReopenSuccess(id, parsedAuthTransaction));
            yield put(modalsShowAddUpdateAuthUsers(parsedAuthTransaction.id));
            yield put(modalsHideAuthTransactionDetail());
        }

        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(showNotification(applicationError.message, 'error'));
        yield put(authTransactionReopenError(id, applicationError.message));
    }
}

function* closeExternalAuthTransactionRequest(action) {
    const { payload } = action;
    const { id, hash } = payload;

    try {
        const serverRequest = createServerRequest({ authTransactionId: _parseInt(id), hash });
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-public/close-external-auth-transaction');

        if (response) {
            const { authTransaction } = response.jsonData;
            const parsedAuthTransaction = parseAuthTransaction(authTransaction);
            yield put(closeExternalAuthTransactionSuccess(id, parsedAuthTransaction));
        }

        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(showNotification(applicationError.message, 'error'));
        yield put(closeExternalAuthTransactionError(id, applicationError.message));
    }
}

function* getAuthTransactionByHashRequest(action) {
    const { payload } = action;
    const { hash } = payload;
    try {
        const serverRequest = createServerRequest({ hash });
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-public/get-auth-transaction-by-hash');
        if (response) {
            const {
                profiles,
                adAccounts,
                useCases,
                platformPermissions,
                useCasePlatformPermissions,
                authTransaction
            } = response.jsonData;
            const parsedProfiles = parseProfiles(profiles);
            const parsedAdAccounts = parseAdAccounts(adAccounts);
            const parsedUseCases = parseUseCases(useCases, useCasePlatformPermissions);
            const parsedPlatformPermissions = parsePlatformPermissions(platformPermissions);
            const parsedAuthTransaction = parseAuthTransaction(authTransaction);
            const parsedAuthTransactionRequirements = parseAuthTransactionsRequirements(authTransaction);
            yield put(getAuthTransactionByHashSuccess(hash, parsedProfiles, parsedAdAccounts, parsedUseCases, parsedPlatformPermissions, parsedAuthTransaction, parsedAuthTransactionRequirements));
        }

        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(getAuthTransactionByHashError(hash, applicationError.message));
    }
}

function* loadAuthTransactionRequestAction() {
    try {
        const serverRequest = createServerRequest();
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/load-auth-transactions');
        if (response) {
            const { authTransactions, profileAuthTransactionRequirements, adAccountAuthTransactionRequirements } = parseAuthTransactionEntities(response.jsonData);
            yield put(loadAuthTransactionsSuccess(authTransactions, profileAuthTransactionRequirements, adAccountAuthTransactionRequirements));
        }

        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(loadAuthTransactionsError(applicationError.message));
    }
}

function* updateAuthTransactionNameRequest(action) {
    const { payload } = action;
    const { authTransactionId, name } = payload;
    try {
        const serverRequest = createServerRequest({ id: authTransactionId, name });
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/update-auth-transaction-name');
        if (response) {
            const { authTransaction } = response.jsonData;
            const parsedAuthTransaction = parseAuthTransaction(authTransaction);
            yield put(updateAuthTransactionNameSuccess(parsedAuthTransaction.id, parsedAuthTransaction.name));
        }

        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(showNotification(applicationError.message, 'error'));
        yield put(updateAuthTransactionNameError(authTransactionId, applicationError.message));
    }
}

export default function* authTransactionSagas() {
    yield takeEvery(CREATE_AUTH_TRANSACTION_REQUEST, createAuthTransactionRequest);
    yield takeEvery(CREATE_BASIC_AUTH_TRANSACTION_REQUEST, createBasicAuthTransactionRequest);
    yield takeEvery(CREATE_ONBOARDING_BASIC_AUTH_TRANSACTION_REQUEST, createOnboardingBasicAuthTransactionRequest);
    yield takeEvery(UPDATE_AUTH_TRANSACTION_NAME_REQUEST, updateAuthTransactionNameRequest);
    yield takeEvery(CLOSE_EXTERNAL_AUTH_TRANSACTION_REQUEST, closeExternalAuthTransactionRequest);
    yield takeEvery(CLOSE_AUTH_TRANSACTION_REQUEST, closeAuthTransactionRequest);
    yield takeEvery(AUTH_TRANSACTION_REOPEN_REQUEST, reopenAuthTransactionRequest);
    yield takeEvery(GET_AUTH_TRANSACTION_BY_HASH_REQUEST, getAuthTransactionByHashRequest);
    yield takeEvery([
        loadAuthTransactionsRequest.type,
        PROFILE_DELETE_SUCCESS,
        PROFILE_FORCE_DELETE_SUCCESS,
        adAccountDeleteSuccess.type,
        adAccountForceDeleteSuccess.type,
        USER_LOGGED_IN
    ], loadAuthTransactionRequestAction);
}
