import Notification from 'src/components/notifications/layout/Notification';
import Feedback from 'src/components/feedback/Feedback';
import FeedbackLink from 'src/components/feedback/FeedbackLink';
import PropTypes from 'prop-types';
import React from 'react';

const Default = (props) => {
    const { message, level, hideNotification } = props;

    return (
        <Notification>
            <Feedback type={level} responsive={false} content={message} link={<FeedbackLink onClick={hideNotification}>Close</FeedbackLink>}>
                {message}
            </Feedback>
        </Notification>
    );
};

Default.propTypes = {
    level: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    hideNotification: PropTypes.func
};

export default Default;
