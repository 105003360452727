export const YOUTUBE_AUTH_USER_CONNECT_REQUEST = 'YOUTUBE_AUTH_USER_CONNECT_REQUEST';
export const YOUTUBE_AUTH_USER_CONNECT_SUCCESS = 'YOUTUBE_AUTH_USER_CONNECT_SUCCESS';
export const YOUTUBE_AUTH_USER_CONNECT_ERROR = 'YOUTUBE_AUTH_USER_CONNECT_ERROR';

export const YOUTUBE_AUTH_USER_EXTERNAL_CONNECT_REQUEST = 'YOUTUBE_AUTH_USER_EXTERNAL_CONNECT_REQUEST';
export const YOUTUBE_AUTH_USER_EXTERNAL_CONNECT_SUCCESS = 'YOUTUBE_AUTH_USER_EXTERNAL_CONNECT_SUCCESS';
export const YOUTUBE_AUTH_USER_EXTERNAL_CONNECT_ERROR = 'YOUTUBE_AUTH_USER_EXTERNAL_CONNECT_ERROR';

export const youtubeAuthUserConnectRequest = (identifier, authTransactionId, authTransactionRequirements, profile) => ({
    type: YOUTUBE_AUTH_USER_CONNECT_REQUEST,
    payload: {
        identifier,
        authTransactionId,
        authTransactionRequirements,
        profile
    }
});

export const youtubeAuthUserConnectSuccess = (
    identifier,
    authTransaction,
    authTransactionRequirements
) => ({
    type: YOUTUBE_AUTH_USER_CONNECT_SUCCESS,
    payload: {
        identifier,
        authTransaction,
        authTransactionRequirements
    }
});

export const youtubeAuthUserConnectError = (identifier, error) => ({
    type: YOUTUBE_AUTH_USER_CONNECT_ERROR,
    payload: { identifier, error }
});

export const youtubeAuthUserExternalConnectRequest = (hash, identifier, authTransactionId, authTransactionRequirements, profile) => ({
    type: YOUTUBE_AUTH_USER_EXTERNAL_CONNECT_REQUEST,
    payload: {
        hash,
        identifier,
        authTransactionId,
        authTransactionRequirements,
        profile
    }
});

export const youtubeAuthUserExternalConnectSuccess = (
    identifier,
    authTransaction,
    authTransactionRequirements
) => ({
    type: YOUTUBE_AUTH_USER_EXTERNAL_CONNECT_SUCCESS,
    payload: {
        identifier,
        authTransaction,
        authTransactionRequirements
    }
});

export const youtubeAuthUserExternalConnectError = (identifier, error) => ({
    type: YOUTUBE_AUTH_USER_EXTERNAL_CONNECT_ERROR,
    payload: { identifier, error }
});
