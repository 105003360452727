import * as customPropTypes from 'src/customPropTypes';
import React from 'react';
import styles from 'src/stylesheets/feedbackLinkGroup.scss';

// type gets injected from FeedbackComponent
const FeedbackLinkGroup = ({ children, type }) => (
    <ul className={styles.links}>
        {
            React.Children.map(children, (child) => {
                if (child) {
                    return <li>{type ? React.cloneElement(child, { type }) : child}</li>;
                }
                return null;
            })
        }
    </ul>
);

FeedbackLinkGroup.propTypes = {
    children: customPropTypes.children.isRequired,
    type: customPropTypes.feedbackTypes
};

export default FeedbackLinkGroup;
