import {
    CLEAN_CLOSE_AUTH_TRANSACTION_STATE,
    CLOSE_AUTH_TRANSACTION_ERROR,
    CLOSE_AUTH_TRANSACTION_REQUEST,
    CLOSE_AUTH_TRANSACTION_SUCCESS,
    CLOSE_EXTERNAL_AUTH_TRANSACTION_ERROR,
    CLOSE_EXTERNAL_AUTH_TRANSACTION_REQUEST,
    CLOSE_EXTERNAL_AUTH_TRANSACTION_SUCCESS,
    CREATE_AUTH_TRANSACTION_SUCCESS,
    CREATE_BASIC_AUTH_TRANSACTION_REQUEST,
    CREATE_BASIC_AUTH_TRANSACTION_SUCCESS,
    CREATE_BASIC_AUTH_TRANSACTION_ERROR,
    CREATE_ONBOARDING_BASIC_AUTH_TRANSACTION_SUCCESS,
    CREATE_ONBOARDING_BASIC_AUTH_TRANSACTION_ERROR,
    CREATE_ONBOARDING_BASIC_AUTH_TRANSACTION_REQUEST,
    GET_AUTH_TRANSACTION_BY_HASH_ERROR,
    GET_AUTH_TRANSACTION_BY_HASH_REQUEST,
    GET_AUTH_TRANSACTION_BY_HASH_SUCCESS,
    UPDATE_AUTH_TRANSACTION_NAME_ERROR,
    UPDATE_AUTH_TRANSACTION_NAME_REQUEST,
    UPDATE_AUTH_TRANSACTION_NAME_SUCCESS,
    AUTH_TRANSACTION_REOPEN_ERROR,
    AUTH_TRANSACTION_REOPEN_SUCCESS,
    AUTH_TRANSACTION_REOPEN_REQUEST,
    loadAuthTransactionsSuccess
} from 'src/actions/authTransactions';
import { createAsyncStatesReducerForAction, createAsyncStatesReducerForActionsWithErrorAndSuccessStates } from 'src/reducers/utils';
import {
    FACEBOOK_AUTH_USER_CONNECT_SUCCESS,
    FACEBOOK_AUTH_USER_EXTERNAL_CONNECT_SUCCESS,
    FACEBOOK_AUTH_USER_EXTERNAL_FORCE_CONNECT_SUCCESS,
    FACEBOOK_AUTH_USER_FORCE_CONNECT_SUCCESS
} from 'src/actions/graphAuthUsers';
import {
    LINKED_IN_AUTH_USER_CONNECT_SUCCESS,
    LINKED_IN_AUTH_USER_EXTERNAL_CONNECT_SUCCESS,
    LINKED_IN_AUTH_USER_EXTERNAL_FORCE_CONNECT_SUCCESS,
    LINKED_IN_AUTH_USER_FORCE_CONNECT_SUCCESS
} from 'src/actions/linkedInAuthUsers';
import {
    SNAPCHAT_AUTH_USER_CONNECT_SUCCESS,
    SNAPCHAT_AUTH_USER_EXTERNAL_CONNECT_SUCCESS,
    SNAPCHAT_AUTH_USER_EXTERNAL_FORCE_CONNECT_SUCCESS,
    SNAPCHAT_AUTH_USER_FORCE_CONNECT_SUCCESS
} from 'src/actions/snapchatAuthUsers';
import {
    TWITTER_AUTH_USER_CONNECT_SUCCESS,
    TWITTER_AUTH_USER_EXTERNAL_CONNECT_SUCCESS
} from 'src/actions/twitterAuthUsers';
import {
    YOUTUBE_AUTH_USER_CONNECT_SUCCESS,
    YOUTUBE_AUTH_USER_EXTERNAL_CONNECT_SUCCESS
} from 'src/actions/youtubeAuthUsers';
import {
    TIKTOK_AUTH_USER_CONNECT_SUCCESS,
    TIKTOK_AUTH_USER_EXTERNAL_CONNECT_SUCCESS
} from 'src/actions/tiktokAuthUsers';
import { combineReducers } from 'redux';

const allIds = (state = [], action) => {
    const { type, payload } = action;
    switch (type) {
        case loadAuthTransactionsSuccess.type: {
            const { authTransactions } = payload;
            return [...Object.keys(authTransactions)];
        }
        case CREATE_AUTH_TRANSACTION_SUCCESS:
        case CREATE_BASIC_AUTH_TRANSACTION_SUCCESS:
        case GET_AUTH_TRANSACTION_BY_HASH_SUCCESS:
        case CREATE_ONBOARDING_BASIC_AUTH_TRANSACTION_SUCCESS: {
            const { id } = payload.authTransaction;
            return [...state, id];
        }

        default:
            return state;
    }
};

const byId = (state = {}, action) => {
    const { payload, type } = action;
    switch (type) {
        case loadAuthTransactionsSuccess.type: {
            const { authTransactions } = payload;
            return Object.assign({}, state, authTransactions);
        }
        case CREATE_AUTH_TRANSACTION_SUCCESS:
        case CREATE_BASIC_AUTH_TRANSACTION_SUCCESS:
        case CREATE_ONBOARDING_BASIC_AUTH_TRANSACTION_SUCCESS:
        case CLOSE_AUTH_TRANSACTION_SUCCESS:
        case CLOSE_EXTERNAL_AUTH_TRANSACTION_SUCCESS:
        case GET_AUTH_TRANSACTION_BY_HASH_SUCCESS:
        case FACEBOOK_AUTH_USER_CONNECT_SUCCESS:
        case FACEBOOK_AUTH_USER_EXTERNAL_CONNECT_SUCCESS:
        case FACEBOOK_AUTH_USER_EXTERNAL_FORCE_CONNECT_SUCCESS:
        case LINKED_IN_AUTH_USER_CONNECT_SUCCESS:
        case LINKED_IN_AUTH_USER_FORCE_CONNECT_SUCCESS:
        case LINKED_IN_AUTH_USER_EXTERNAL_CONNECT_SUCCESS:
        case LINKED_IN_AUTH_USER_EXTERNAL_FORCE_CONNECT_SUCCESS:
        case TWITTER_AUTH_USER_CONNECT_SUCCESS:
        case TWITTER_AUTH_USER_EXTERNAL_CONNECT_SUCCESS:
        case YOUTUBE_AUTH_USER_CONNECT_SUCCESS:
        case YOUTUBE_AUTH_USER_EXTERNAL_CONNECT_SUCCESS:
        case FACEBOOK_AUTH_USER_FORCE_CONNECT_SUCCESS:
        case SNAPCHAT_AUTH_USER_EXTERNAL_CONNECT_SUCCESS:
        case SNAPCHAT_AUTH_USER_CONNECT_SUCCESS:
        case SNAPCHAT_AUTH_USER_FORCE_CONNECT_SUCCESS:
        case SNAPCHAT_AUTH_USER_EXTERNAL_FORCE_CONNECT_SUCCESS:
        case TIKTOK_AUTH_USER_EXTERNAL_CONNECT_SUCCESS:
        case TIKTOK_AUTH_USER_CONNECT_SUCCESS:
        case AUTH_TRANSACTION_REOPEN_SUCCESS: {
            const { authTransaction } = payload;
            if (authTransaction) {
                return Object.assign({}, state, { [authTransaction.id]: authTransaction });
            }
            return state;
        }
        case UPDATE_AUTH_TRANSACTION_NAME_SUCCESS: {
            const { authTransactionId, name } = payload;
            return Object.assign({}, state, {
                [authTransactionId]: Object.assign({}, state[authTransactionId], { name })
            });
        }
        default:
            return state;
    }
};

const asyncStatesByAction = combineReducers({
    createBasic: createAsyncStatesReducerForAction(
        () => 'state',
        [
            CREATE_BASIC_AUTH_TRANSACTION_REQUEST,
            CREATE_ONBOARDING_BASIC_AUTH_TRANSACTION_REQUEST
        ],
        [
            CREATE_BASIC_AUTH_TRANSACTION_SUCCESS,
            CREATE_BASIC_AUTH_TRANSACTION_ERROR,
            CREATE_ONBOARDING_BASIC_AUTH_TRANSACTION_SUCCESS,
            CREATE_ONBOARDING_BASIC_AUTH_TRANSACTION_ERROR
        ]
    ),
    close: createAsyncStatesReducerForActionsWithErrorAndSuccessStates(
        (payload) => payload.id,
        [CLOSE_AUTH_TRANSACTION_REQUEST, CLOSE_EXTERNAL_AUTH_TRANSACTION_REQUEST],
        [CLOSE_AUTH_TRANSACTION_SUCCESS, CLOSE_EXTERNAL_AUTH_TRANSACTION_SUCCESS],
        [CLOSE_AUTH_TRANSACTION_ERROR, CLOSE_EXTERNAL_AUTH_TRANSACTION_ERROR],
        [CLEAN_CLOSE_AUTH_TRANSACTION_STATE]
    ),
    reopen: createAsyncStatesReducerForActionsWithErrorAndSuccessStates(
        (payload) => payload.id,
        [AUTH_TRANSACTION_REOPEN_REQUEST],
        [AUTH_TRANSACTION_REOPEN_SUCCESS],
        [AUTH_TRANSACTION_REOPEN_ERROR],
        [CLEAN_CLOSE_AUTH_TRANSACTION_STATE]
    ),
    fetchByHash: createAsyncStatesReducerForActionsWithErrorAndSuccessStates(
        (payload) => payload.hash,
        [GET_AUTH_TRANSACTION_BY_HASH_REQUEST],
        [GET_AUTH_TRANSACTION_BY_HASH_SUCCESS],
        [GET_AUTH_TRANSACTION_BY_HASH_ERROR]
    ),
    updateName: createAsyncStatesReducerForAction(
        (payload) => payload.authTransactionId,
        [UPDATE_AUTH_TRANSACTION_NAME_REQUEST],
        [UPDATE_AUTH_TRANSACTION_NAME_SUCCESS, UPDATE_AUTH_TRANSACTION_NAME_ERROR]
    )
});

export const authTransactions = combineReducers({
    allIds,
    byId,
    asyncStatesByAction
});
