import * as customPropTypes from 'src/customPropTypes';
import _isString from 'lodash/isString';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import { selectAccountOptions } from 'src/selectors/loggedInUser';
import { tryGetTimezoneAbbrevOrUtcOffsetOrNull } from 'src/components/dateSelector/utils';

export const getLocalString = (date, format, accountOptions, appendTimezone = false, dateInTimezone = 'UTC', timezone = '') => {
    if (!_isString(date)) {
        return 'n/a';
    }

    const timezoneToUse = timezone || accountOptions.timezone;
    const dateInGivenTimezone = moment.tz(date, dateInTimezone);
    const dateInDesiredTimezone = dateInGivenTimezone.clone().tz(timezoneToUse);

    let dateLocalStr = dateInDesiredTimezone.format(accountOptions.dateFormat);
    if (format === 'datetime') {
        dateLocalStr += ` ${dateInDesiredTimezone.format('HH:mm')}`;
    }
    if (appendTimezone) {
        const timezoneOrNull = tryGetTimezoneAbbrevOrUtcOffsetOrNull(timezoneToUse, dateInGivenTimezone);
        if (timezoneOrNull) {
            dateLocalStr = `${dateLocalStr} ${timezoneOrNull}`;
        }
    }
    return dateLocalStr;
};

const Date = (props) => {
    const {
        date,
        format,
        accountOptions,
        appendTimezone,
        dateInTimezone,
        dateToTimezone
    } = props;

    return (
        <span>{getLocalString(date, format, accountOptions, appendTimezone, dateInTimezone, dateToTimezone)}</span>
    );
};

Date.propTypes = {
    date: PropTypes.string, // expected in "YYYY-MM-DD HH:mm:ss" format
    dateInTimezone: PropTypes.string, // this is the expected timezone of the date string, it defaults to UTC
    format: PropTypes.oneOf(['date', 'datetime']),
    accountOptions: customPropTypes.accountOptions.isRequired,
    appendTimezone: PropTypes.bool, // controls wether the output contains the Timezone appendix
    dateToTimezone: PropTypes.string // defaults to account options if not further specified
};

Date.defaultProps = {
    dateInTimezone: 'UTC',
    format: 'date',
    appendTimezone: false
};

const mapStateToProps = (state) => ({
    accountOptions: selectAccountOptions(state)
});

export default connect(mapStateToProps)(Date);
