import { createSelector } from 'reselect';

const getSharedDashboardFromStore = (state) => state.sharedDashboard;

export const selectSharedDashboard = getSharedDashboardFromStore;

// Hash is only the identifier, for data loading now please stick to the credentials
export const selectSharedDashboardHash = (state) => { const sharedDashboard = selectSharedDashboard(state); return sharedDashboard.hash; };

export const selectIsSharedDashboardContext = (state) => {
    const sharedDashboard = selectSharedDashboard(state);
    if (!sharedDashboard) {
        return false;
    }
    return true;
};

export const selectSharedDashboardId = (state) => { const sharedDashboard = selectSharedDashboard(state); return sharedDashboard.dashboardId; };

export const makeSelectSharedDashboardMetricLink = () => createSelector(
    [
        selectSharedDashboardHash,
        (_, dashboardMetricId) => dashboardMetricId
    ],
    (hash, dashboardMetricId) => Object.assign({}, { pathname: `/shared-dashboard/${dashboardMetricId}` }, { query: { hash } })
);

export const makeSelectSharedDashboardLink = () => createSelector(
    [
        selectSharedDashboardHash,
    ],
    (hash) => Object.assign({}, { pathname: '/shared-dashboard' }, { query: { hash } })
);
