import * as customPropTypes from 'src/customPropTypes';
import { connect } from 'react-redux';
import { makeSelectDashboardLink } from 'src/selectors/filterSelectors';
import Notification from 'src/components/notifications/layout/Notification';
import Feedback from 'src/components/feedback/Feedback';
import FeedbackLink from 'src/components/feedback/FeedbackLink';
import PropTypes from 'prop-types';
import React from 'react';
import { getDashboardContext } from 'src/utils/filterSelectors';

const DashboardAddSuccess = (props) => {
    const { dashboardName, dashboardLink } = props;
    return (
        <Notification>
            <Feedback
              type="success"
              responsive={false}
              content={`The dashboard "${dashboardName}" was successfully added.`}
              link={<FeedbackLink to={dashboardLink}>View Dashboard</FeedbackLink>}
            />
        </Notification>
    );
};

DashboardAddSuccess.propTypes = {
    dashboardId: PropTypes.string.isRequired,
    dashboardName: PropTypes.string.isRequired,
    dashboardLink: customPropTypes.linkTo.isRequired
};

const makeMapStateToProps = () => {
    const selectDashboardLink = makeSelectDashboardLink();
    return (state, ownProps) => ({
        dashboardLink: selectDashboardLink(state, getDashboardContext(ownProps.dashboardId), ownProps.dashboardId)
    });
};

export default connect(makeMapStateToProps)(DashboardAddSuccess);
