import moment from 'moment-timezone';
import { getCookieOrNull, setCookie } from 'src/utils/cookies';
import { v4 as uuidv4 } from 'uuid';

const dashboardShareLinkVisitorIdentifierCookieName = 'QINTERACTIVEDASHBOARD';

export const createInitialFormValuesFromDashboardShareLink = (dashboardShareLink) => ({
    id: dashboardShareLink.id,
    dashboardId: dashboardShareLink.dashboardId,
    profileSelection: dashboardShareLink.profileSelection,
    dateSelection: dashboardShareLink.dateSelection,
    additionalFilterSelection: dashboardShareLink.additionalFilterSelection !== null ? dashboardShareLink.additionalFilterSelection : {},
    isPasswordProtected: dashboardShareLink.isPasswordProtected,
    password: '',
    hasExpiry: dashboardShareLink.hasExpiry,
    validUntil: dashboardShareLink.validUntil
});

export const getInitialFormValues = (dashboardId, selectedProfilesOrGroups, dateSelection, additionalFilter) => ({
    dashboardId,
    profileSelection: selectedProfilesOrGroups,
    dateSelection,
    additionalFilterSelection: additionalFilter,
    isPasswordProtected: false,
    password: '',
    hasExpiry: false,
    validUntil: null
});

export const isUntilDateExpired = (validUntil) => {
    const validUntilDate = moment.tz(validUntil, 'UTC').unix();
    return validUntilDate < moment.utc().unix();
};

export const isDashboardShareLinkExpired = (dashboardShareLink) => {
    const { hasExpiry, validUntil } = dashboardShareLink;
    return hasExpiry ? isUntilDateExpired(validUntil) : false;
};

export const setVisitorIdentifierInCookie = () => {
    const uuidInCookie = getCookieOrNull(dashboardShareLinkVisitorIdentifierCookieName);
    if (uuidInCookie === null) {
        const newUUID = uuidv4();
        const expiry = moment().tz('UTC').add(365, 'days').format('ddd, D MMM YYYY, HH:mm:ss zz');
        setCookie(dashboardShareLinkVisitorIdentifierCookieName, newUUID, expiry);
    }
};
