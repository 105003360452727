import { call, put, takeEvery } from 'redux-saga/effects';
import createServerRequest from 'src/requestHandling/createServerRequest';
import {
    getUniqueUseCaseIdsWithAuthTransactionReqIds,
    handleAuthorizedServerRequest
} from 'src/sagas/utils';
import {
    parseAuthTransaction,
    parseAuthTransactionsRequirements
} from 'src/parsers';
import {
    TWITTER_AUTH_USER_CONNECT_REQUEST,
    TWITTER_AUTH_USER_EXTERNAL_CONNECT_REQUEST,
    twitterAuthUserConnectError,
    twitterAuthUserConnectSuccess,
    twitterAuthUserExternalConnectError,
    twitterAuthUserExternalConnectSuccess
} from 'src/actions/twitterAuthUsers';
import { showAuthenticatedProfilesByAuthUserSuccessOrWarningNotification } from 'src/actions/notifications';
import { twitterAuthUserAuthenticationRequest } from 'src/sagas/authUsers';
import { generateRandomTwitterRequestId } from 'src/utils/authUsers';

const getScreenNameFromLink = (link) => link.substring(link.lastIndexOf('/') + 1, link.length);

function* twitterAuthUserConnectRequest(action) {
    const { payload } = action;
    const {
        identifier,
        authTransactionId,
        authTransactionRequirements,
        profile
    } = payload;
    const { authTransactionRequirementIds } = getUniqueUseCaseIdsWithAuthTransactionReqIds({ [profile.id]: authTransactionRequirements });
    const screenName = getScreenNameFromLink(profile.link);
    const requestId = generateRandomTwitterRequestId();
    try {
        const { response, serverError } = yield call(twitterAuthUserAuthenticationRequest, requestId, screenName);
        if (response) {
            const { oauthToken, oauthVerifier } = response;
            const authorizedServerRequest = createServerRequest({
                screenName,
                requestId,
                oauthToken,
                oauthVerifier,
                authTransactionId,
                authTransactionRequirementIds: JSON.stringify(authTransactionRequirementIds)
            });
            const { response: authorizedResponse, serverError: authorizedServerError } = yield handleAuthorizedServerRequest(authorizedServerRequest, '/client-index/finish-twitter-auth-user-connection-callback-url');
            if (authorizedResponse) {
                const {
                    authTransaction,
                    connectedProfileIds
                } = authorizedResponse.jsonData;
                const parsedAuthTransaction = parseAuthTransaction(authTransaction);
                const parsedAuthTransactionRequirements = parseAuthTransactionsRequirements(authTransaction);
                yield put(twitterAuthUserConnectSuccess(identifier, parsedAuthTransaction, parsedAuthTransactionRequirements));
                yield put(showAuthenticatedProfilesByAuthUserSuccessOrWarningNotification(connectedProfileIds.map((id) => `${id}`), [profile.id]));
            }
            if (authorizedServerError) {
                throw authorizedServerError;
            }
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(twitterAuthUserConnectError(identifier, applicationError.message));
    }
}

function* twitterAuthUserExternalConnectRequest(action) {
    const { payload } = action;
    const {
        hash,
        identifier,
        authTransactionId,
        authTransactionRequirements,
        profile
    } = payload;

    const { authTransactionRequirementIds } = getUniqueUseCaseIdsWithAuthTransactionReqIds({ [profile.id]: authTransactionRequirements });
    const screenName = getScreenNameFromLink(profile.link);
    const requestId = generateRandomTwitterRequestId();
    try {
        const { response, serverError } = yield call(twitterAuthUserAuthenticationRequest, requestId, screenName);
        if (response) {
            // eslint-disable-next-line camelcase
            const { oauthToken, oauthVerifier } = response;
            const authorizedServerRequest = createServerRequest({
                screenName,
                requestId,
                oauthToken,
                oauthVerifier,
                authTransactionId,
                authTransactionRequirementIds: JSON.stringify(authTransactionRequirementIds),
                hash
            });
            const { response: authorizedResponse, serverError: authorizedServerError } = yield handleAuthorizedServerRequest(authorizedServerRequest, '/client-public/finish-twitter-external-auth-user-connection-callback-url');
            if (authorizedResponse) {
                const {
                    authTransaction,
                    connectedProfileIds
                } = authorizedResponse.jsonData;
                const parsedAuthTransaction = parseAuthTransaction(authTransaction);
                const parsedAuthTransactionRequirements = parseAuthTransactionsRequirements(authTransaction);
                yield put(twitterAuthUserExternalConnectSuccess(
                    identifier,
                    parsedAuthTransaction,
                    parsedAuthTransactionRequirements
                ));
                yield put(showAuthenticatedProfilesByAuthUserSuccessOrWarningNotification(connectedProfileIds.map((id) => `${id}`), [profile.id]));
            }
            if (authorizedServerError) {
                throw authorizedServerError;
            }
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(twitterAuthUserExternalConnectError(identifier, applicationError.message));
    }
}

export default function* twitterAuthUsersSagas() {
    yield takeEvery(TWITTER_AUTH_USER_CONNECT_REQUEST, twitterAuthUserConnectRequest);
    yield takeEvery(TWITTER_AUTH_USER_EXTERNAL_CONNECT_REQUEST, twitterAuthUserExternalConnectRequest);
}
