import * as customPropTypes from 'src/customPropTypes';
import { connect } from 'react-redux';
import Feedback from 'src/components/feedback/Feedback';
import Notification from 'src/components/notifications/layout/Notification';
import PropTypes from 'prop-types';
import React from 'react';
import { makeSelectDashboardsByIds } from 'src/selectors/dashboards';
import { makeSelectFoldersByFolderIds } from 'src/selectors/folders';

const FoldersAndDashboardDeleteSuccessOrWarning = (props) => {
    const {
        failedFolders, numberOfSuccessFolders, failedDashboards, numberOfSuccessDashboards
    } = props;
    let failedContent = '';
    let successContent = '';

    let type = '';
    const sumOfIds = failedFolders.length + numberOfSuccessFolders + failedDashboards.length + numberOfSuccessDashboards;
    const sumOfAllErrorIds = failedFolders.length + failedDashboards.length;

    if (sumOfIds !== sumOfAllErrorIds) {
        successContent = (
            <span>
                {
                    numberOfSuccessFolders > 0
                        && <span>{numberOfSuccessFolders} folder{numberOfSuccessFolders > 1 ? 's' : ''} </span>
                }
                {
                    numberOfSuccessFolders > 0 && numberOfSuccessDashboards > 0
                        && <span> and </span>
                }
                {
                    numberOfSuccessDashboards > 0
                        && <span>{numberOfSuccessDashboards} dashboard{numberOfSuccessDashboards > 1 ? 's' : ''} </span>
                }
                {
                    (numberOfSuccessFolders + numberOfSuccessDashboards) > 1 ? 'were ' : 'was '
                }
                successfully deleted.<br />
            </span>
        );
    }

    if (sumOfAllErrorIds > 0) {
        const failedFolderNames = failedFolders.map((folder) => folder.name);
        const failedDashboardNames = failedDashboards.map((dashboard) => dashboard.name);
        failedContent = (
            <span>
                We were unable to delete the following folders and/or dashboards. Please try again in a few moments.<br /><br />
                {
                    failedFolderNames.length > 0
                        && (
                        <span>
                            Folders: <b>{failedFolderNames.join(', ')}</b>
                            <br />
                        </span>
                        )
                }
                {
                    failedDashboardNames.length > 0
                        && (
                        <span>
                            Dashboards: <b>{failedDashboardNames.join(', ')}</b>
                            <br />
                        </span>
                        )
                }
            </span>
        );
    }

    if (sumOfAllErrorIds === 0) {
        type = 'success';
    } else if (sumOfIds === sumOfAllErrorIds) {
        type = 'error';
    } else {
        type = 'warning';
    }

    return (
        <Notification>
            <Feedback
              type={type}
              responsive={false}
              content={(
                  <div>
                      {successContent}
                      {failedContent}
                  </div>
              )}
            />
        </Notification>
    );
};

FoldersAndDashboardDeleteSuccessOrWarning.propTypes = {
    failedFolders: customPropTypes.folders.isRequired,
    numberOfSuccessFolders: PropTypes.number.isRequired,
    failedDashboards: customPropTypes.dashboards.isRequired,
    numberOfSuccessDashboards: PropTypes.number.isRequired
};

const makeMapStateToProps = () => {
    const selectFoldersByFolderIds = makeSelectFoldersByFolderIds();
    const selectDashboardsByIds = makeSelectDashboardsByIds();
    return (state, ownProps) => {
        const { foldersResponses, dashboardsResponses } = ownProps;

        return {
            failedFolders: selectFoldersByFolderIds(state, foldersResponses.errors),
            numberOfSuccessFolders: foldersResponses.success.length,
            failedDashboards: selectDashboardsByIds(state, dashboardsResponses.errors),
            numberOfSuccessDashboards: dashboardsResponses.success.length
        };
    };
};

export default connect(makeMapStateToProps)(FoldersAndDashboardDeleteSuccessOrWarning);
