import * as customPropTypes from 'src/customPropTypes';
import { connect } from 'react-redux';
import Feedback from 'src/components/feedback/Feedback';
import { makeSelectProfilesByIds } from 'src/selectors/profiles';
import Notification from 'src/components/notifications/layout/Notification';
import PropTypes from 'prop-types';
import React from 'react';

const ProfileBatchAddingSuccessOrWarning = (props) => {
    const { profilesWithErrors } = props;

    let content = 'The selected profiles were successfully reassigned to the given groups.';
    let type = 'success';

    if (profilesWithErrors.length > 0) {
        const names = profilesWithErrors.map((profile) => profile.defaultName);
        type = 'warning';
        content = (
            <span>
                Not all group assignments could be completed for the following profiles:<br />
                <b>{names.join(', ')}</b>
            </span>
        );
    }
    return (
        <Notification>
            <Feedback
              type={type}
              responsive={false}
              content={content}
            />
        </Notification>
    );
};

ProfileBatchAddingSuccessOrWarning.propTypes = {
    profileAddOrRemoveResults: PropTypes.object.isRequired,
    profilesWithErrors: customPropTypes.profiles.isRequired
};

const makeMapStateToProps = () => {
    const selectProfilesByIds = makeSelectProfilesByIds();
    return (state, ownProps) => ({
        profilesWithErrors: selectProfilesByIds(state, ownProps.profileAddOrRemoveResults.errors)
    });
};

export default connect(makeMapStateToProps)(ProfileBatchAddingSuccessOrWarning);
