export const TWITTER_AUTH_USER_CONNECT_REQUEST = 'TWITTER_AUTH_USER_CONNECT_REQUEST';
export const TWITTER_AUTH_USER_CONNECT_SUCCESS = 'TWITTER_AUTH_USER_CONNECT_SUCCESS';
export const TWITTER_AUTH_USER_CONNECT_ERROR = 'TWITTER_AUTH_USER_CONNECT_ERROR';

export const TWITTER_AUTH_USER_EXTERNAL_CONNECT_REQUEST = 'TWITTER_AUTH_USER_EXTERNAL_CONNECT_REQUEST';
export const TWITTER_AUTH_USER_EXTERNAL_CONNECT_SUCCESS = 'TWITTER_AUTH_USER_EXTERNAL_CONNECT_SUCCESS';
export const TWITTER_AUTH_USER_EXTERNAL_CONNECT_ERROR = 'TWITTER_AUTH_USER_EXTERNAL_CONNECT_ERROR';

export const twitterAuthUserConnectRequest = (identifier, authTransactionId, authTransactionRequirements, profile) => ({
    type: TWITTER_AUTH_USER_CONNECT_REQUEST,
    payload: {
        identifier,
        authTransactionId,
        authTransactionRequirements,
        profile
    }
});

export const twitterAuthUserConnectSuccess = (
    identifier,
    authTransaction,
    authTransactionRequirements
) => ({
    type: TWITTER_AUTH_USER_CONNECT_SUCCESS,
    payload: {
        identifier,
        authTransaction,
        authTransactionRequirements
    }
});

export const twitterAuthUserConnectError = (identifier, error) => ({
    type: TWITTER_AUTH_USER_CONNECT_ERROR,
    payload: { identifier, error }
});

export const twitterAuthUserExternalConnectRequest = (hash, identifier, authTransactionId, authTransactionRequirements, profile) => ({
    type: TWITTER_AUTH_USER_EXTERNAL_CONNECT_REQUEST,
    payload: {
        hash,
        identifier,
        authTransactionId,
        authTransactionRequirements,
        profile
    }
});

export const twitterAuthUserExternalConnectSuccess = (
    identifier,
    authTransaction,
    authTransactionRequirements
) => ({
    type: TWITTER_AUTH_USER_EXTERNAL_CONNECT_SUCCESS,
    payload: {
        identifier,
        authTransaction,
        authTransactionRequirements
    }
});

export const twitterAuthUserExternalConnectError = (identifier, error) => ({
    type: TWITTER_AUTH_USER_EXTERNAL_CONNECT_ERROR,
    payload: { identifier, error }
});
