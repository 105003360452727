export const LINKED_IN_AUTH_USER_CONNECT_REQUEST = 'LINKED_IN_AUTH_USER_CONNECT_REQUEST';
export const LINKED_IN_AUTH_USER_CONNECT_SUCCESS = 'LINKED_IN_AUTH_USER_CONNECT_SUCCESS';
export const LINKED_IN_AUTH_USER_CONNECT_ERROR = 'LINKED_IN_AUTH_USER_CONNECT_ERROR';

export const LINKED_IN_AUTH_USER_FORCE_CONNECT_REQUEST = 'LINKED_IN_AUTH_USER_FORCE_CONNECT_REQUEST';
export const LINKED_IN_AUTH_USER_FORCE_CONNECT_SUCCESS = 'LINKED_IN_AUTH_USER_FORCE_CONNECT_SUCCESS';
export const LINKED_IN_AUTH_USER_FORCE_CONNECT_ERROR = 'LINKED_IN_AUTH_USER_FORCE_CONNECT_ERROR';

export const LINKED_IN_AUTH_USER_EXTERNAL_CONNECT_REQUEST = 'LINKED_IN_AUTH_USER_EXTERNAL_CONNECT_REQUEST';
export const LINKED_IN_AUTH_USER_EXTERNAL_CONNECT_SUCCESS = 'LINKED_IN_AUTH_USER_EXTERNAL_CONNECT_SUCCESS';
export const LINKED_IN_AUTH_USER_EXTERNAL_CONNECT_ERROR = 'LINKED_IN_AUTH_USER_EXTERNAL_CONNECT_ERROR';

export const LINKED_IN_AUTH_USER_EXTERNAL_FORCE_CONNECT_REQUEST = 'LINKED_IN_AUTH_USER_EXTERNAL_FORCE_CONNECT_REQUEST';
export const LINKED_IN_AUTH_USER_EXTERNAL_FORCE_CONNECT_SUCCESS = 'LINKED_IN_AUTH_USER_EXTERNAL_FORCE_CONNECT_SUCCESS';
export const LINKED_IN_AUTH_USER_EXTERNAL_FORCE_CONNECT_ERROR = 'LINKED_IN_AUTH_USER_EXTERNAL_FORCE_CONNECT_ERROR';

export const linkedInAuthUserConnectRequest = (identifier, authTransactionId, authTransactionRequirementsPerProfile) => ({
    type: LINKED_IN_AUTH_USER_CONNECT_REQUEST,
    payload: {
        identifier,
        authTransactionId,
        authTransactionRequirementsPerProfile
    }
});

export const linkedInAuthUserConnectSuccess = (
    identifier,
    authTransaction,
    authTransactionRequirements
) => ({
    type: LINKED_IN_AUTH_USER_CONNECT_SUCCESS,
    payload: {
        identifier,
        authTransaction,
        authTransactionRequirements
    }
});

export const linkedInAuthUserConnectError = (identifier, error) => ({
    type: LINKED_IN_AUTH_USER_CONNECT_ERROR,
    payload: { identifier, error }
});

export const linkedInAuthUserExternalConnectRequest = (hash, identifier, authTransactionId, authTransactionRequirementsPerProfile) => ({
    type: LINKED_IN_AUTH_USER_EXTERNAL_CONNECT_REQUEST,
    payload: {
        hash,
        identifier,
        authTransactionId,
        authTransactionRequirementsPerProfile
    }
});

export const linkedInAuthUserExternalConnectSuccess = (
    identifier,
    authTransaction,
    authTransactionRequirements
) => ({
    type: LINKED_IN_AUTH_USER_EXTERNAL_CONNECT_SUCCESS,
    payload: {
        identifier,
        authTransaction,
        authTransactionRequirements
    }
});

export const linkedInAuthUserExternalConnectError = (identifier, error) => ({
    type: LINKED_IN_AUTH_USER_EXTERNAL_CONNECT_ERROR,
    payload: { identifier, error }
});

export const linkedInAuthUserForceConnectRequest = (identifier, authTransactionId, authTransactionRequirementIds, accessToken, refreshToken, validUntil, refreshTokenValidUntil, platformUserId, platformUserName, profileIds) => ({
    type: LINKED_IN_AUTH_USER_FORCE_CONNECT_REQUEST,
    payload: {
        identifier,
        authTransactionId,
        authTransactionRequirementIds,
        accessToken,
        refreshToken,
        validUntil,
        refreshTokenValidUntil,
        platformUserId,
        platformUserName,
        profileIds
    }
});

export const linkedInAuthUserForceConnectSuccess = (
    identifier,
    authTransaction,
    authTransactionRequirements
) => ({
    type: LINKED_IN_AUTH_USER_FORCE_CONNECT_SUCCESS,
    payload: {
        identifier,
        authTransaction,
        authTransactionRequirements
    }
});

export const linkedInAuthUserForceConnectError = (identifier, error) => ({
    type: LINKED_IN_AUTH_USER_FORCE_CONNECT_ERROR,
    payload: { identifier, error }
});

export const linkedInAuthUserExternalForceConnectRequest = (hash, identifier, authTransactionId, authTransactionRequirementIds, accessToken, refreshToken, validUntil, refreshTokenValidUntil, platformUserId, platformUserName, profileIds) => ({
    type: LINKED_IN_AUTH_USER_EXTERNAL_FORCE_CONNECT_REQUEST,
    payload: {
        hash,
        identifier,
        authTransactionId,
        authTransactionRequirementIds,
        accessToken,
        refreshToken,
        validUntil,
        refreshTokenValidUntil,
        platformUserId,
        platformUserName,
        profileIds
    }
});

export const linkedInAuthUserExternalForceConnectSuccess = (
    identifier,
    authTransaction,
    authTransactionRequirements
) => ({
    type: LINKED_IN_AUTH_USER_EXTERNAL_FORCE_CONNECT_SUCCESS,
    payload: {
        identifier,
        authTransaction,
        authTransactionRequirements
    }
});

export const linkedInAuthUserExternalForceConnectError = (identifier, error) => ({
    type: LINKED_IN_AUTH_USER_EXTERNAL_FORCE_CONNECT_ERROR,
    payload: { identifier, error }
});
