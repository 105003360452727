export const SNAPCHAT_AUTH_USER_CONNECT_REQUEST = 'SNAPCHAT_AUTH_USER_CONNECT_REQUEST';
export const SNAPCHAT_AUTH_USER_CONNECT_SUCCESS = 'SNAPCHAT_AUTH_USER_CONNECT_SUCCESS';
export const SNAPCHAT_AUTH_USER_CONNECT_ERROR = 'SNAPCHAT_AUTH_USER_CONNECT_ERROR';

export const SNAPCHAT_AUTH_USER_FORCE_CONNECT_REQUEST = 'SNAPCHAT_AUTH_USER_FORCE_CONNECT_REQUEST';
export const SNAPCHAT_AUTH_USER_FORCE_CONNECT_SUCCESS = 'SNAPCHAT_AUTH_USER_FORCE_CONNECT_SUCCESS';
export const SNAPCHAT_AUTH_USER_FORCE_CONNECT_ERROR = 'SNAPCHAT_AUTH_USER_FORCE_CONNECT_ERROR';

export const SNAPCHAT_AUTH_USER_EXTERNAL_CONNECT_REQUEST = 'SNAPCHAT_AUTH_USER_EXTERNAL_CONNECT_REQUEST';
export const SNAPCHAT_AUTH_USER_EXTERNAL_CONNECT_SUCCESS = 'SNAPCHAT_AUTH_USER_EXTERNAL_CONNECT_SUCCESS';
export const SNAPCHAT_AUTH_USER_EXTERNAL_CONNECT_ERROR = 'SNAPCHAT_AUTH_USER_EXTERNAL_CONNECT_ERROR';

export const SNAPCHAT_AUTH_USER_EXTERNAL_FORCE_CONNECT_REQUEST = 'SNAPCHAT_AUTH_USER_EXTERNAL_FORCE_CONNECT_REQUEST';
export const SNAPCHAT_AUTH_USER_EXTERNAL_FORCE_CONNECT_SUCCESS = 'SNAPCHAT_AUTH_USER_EXTERNAL_FORCE_CONNECT_SUCCESS';
export const SNAPCHAT_AUTH_USER_EXTERNAL_FORCE_CONNECT_ERROR = 'SNAPCHAT_AUTH_USER_EXTERNAL_FORCE_CONNECT_ERROR';

export const snapchatAuthUserConnectRequest = (identifier, authTransactionId, authTransactionRequirementsByProfile) => ({
    type: SNAPCHAT_AUTH_USER_CONNECT_REQUEST,
    payload: {
        identifier,
        authTransactionId,
        authTransactionRequirementsByProfile,
    }
});

export const snapchatAuthUserConnectSuccess = (
    identifier,
    authTransaction,
    authTransactionRequirements,
) => ({
    type: SNAPCHAT_AUTH_USER_CONNECT_SUCCESS,
    payload: {
        identifier,
        authTransaction,
        authTransactionRequirements
    }
});

export const snapchatAuthUserConnectError = (identifier, error) => ({
    type: SNAPCHAT_AUTH_USER_CONNECT_ERROR,
    payload: { identifier, error }
});

export const snapchatAuthUserExternalConnectRequest = (hash, identifier, authTransactionId, authTransactionRequirementsByProfile) => ({
    type: SNAPCHAT_AUTH_USER_EXTERNAL_CONNECT_REQUEST,
    payload: {
        hash,
        identifier,
        authTransactionId,
        authTransactionRequirementsByProfile
    }
});

export const snapchatAuthUserExternalConnectSuccess = (
    identifier,
    authTransaction,
    authTransactionRequirements
) => ({
    type: SNAPCHAT_AUTH_USER_EXTERNAL_CONNECT_SUCCESS,
    payload: {
        identifier,
        authTransaction,
        authTransactionRequirements
    }
});

export const snapchatAuthUserExternalConnectError = (identifier, error) => ({
    type: SNAPCHAT_AUTH_USER_EXTERNAL_CONNECT_ERROR,
    payload: { identifier, error }
});

export const snapchatAuthUserForceConnectRequest = (
    identifier, authTransactionId, authTransactionRequirementIds, accessToken,
    refreshAccessToken, expiresIn, platformUserId, displayName, profileIds
) => ({
    type: SNAPCHAT_AUTH_USER_FORCE_CONNECT_REQUEST,
    payload: {
        identifier,
        authTransactionId,
        authTransactionRequirementIds,
        accessToken,
        refreshAccessToken,
        expiresIn,
        platformUserId,
        displayName,
        profileIds
    }
});

export const snapchatAuthUserForceConnectSuccess = (
    identifier,
    authTransaction,
    authTransactionRequirements
) => ({
    type: SNAPCHAT_AUTH_USER_FORCE_CONNECT_SUCCESS,
    payload: {
        identifier,
        authTransaction,
        authTransactionRequirements
    }
});

export const snapchatAuthUserForceConnectError = (identifier, error) => ({
    type: SNAPCHAT_AUTH_USER_FORCE_CONNECT_ERROR,
    payload: { identifier, error }
});

export const snapchatAuthUserExternalForceConnectRequest = (
    hash, identifier, authTransactionId, authTransactionRequirementIds, accessToken,
    refreshAccessToken, expiresIn, platformUserId, displayName, profileIds
) => ({
    type: SNAPCHAT_AUTH_USER_EXTERNAL_FORCE_CONNECT_REQUEST,
    payload: {
        hash,
        identifier,
        authTransactionId,
        authTransactionRequirementIds,
        accessToken,
        refreshAccessToken,
        expiresIn,
        platformUserId,
        displayName,
        profileIds
    }
});

export const snapchatAuthUserExternalForceConnectSuccess = (
    identifier,
    authTransaction,
    authTransactionRequirements
) => ({
    type: SNAPCHAT_AUTH_USER_EXTERNAL_FORCE_CONNECT_SUCCESS,
    payload: {
        identifier,
        authTransaction,
        authTransactionRequirements
    }
});

export const snapchatAuthUserExternalForceConnectError = (identifier, error) => ({
    type: SNAPCHAT_AUTH_USER_EXTERNAL_FORCE_CONNECT_ERROR,
    payload: { identifier, error }
});
