import {
    call, put, takeEvery
} from 'redux-saga/effects';
import { getUniqueUseCaseIdsWithAuthTransactionReqIds, handleAuthorizedServerRequest } from 'src/sagas/utils';
import {
    FACEBOOK_AUTH_USER_CONNECT_REQUEST,
    FACEBOOK_AUTH_USER_EXTERNAL_CONNECT_REQUEST,
    FACEBOOK_AUTH_USER_EXTERNAL_FORCE_CONNECT_REQUEST,
    FACEBOOK_AUTH_USER_FORCE_CONNECT_REQUEST,
    facebookAuthUserConnectError,
    facebookAuthUserConnectSuccess,
    facebookAuthUserExternalConnectError,
    facebookAuthUserExternalConnectSuccess,
    facebookAuthUserExternalForceConnectError,
    facebookAuthUserExternalForceConnectSuccess,
    facebookAuthUserForceConnectError,
    facebookAuthUserForceConnectSuccess
} from 'src/actions/graphAuthUsers';
import { parseAuthTransaction, parseAuthTransactionsRequirements } from 'src/parsers';
import _parseInt from 'lodash/parseInt';
import createServerRequest from 'src/requestHandling/createServerRequest';
import { modalsShowFacebookAuthUserInAnotherSpaceAuthTransactionWarning } from 'src/actions/overlays';
import { showAuthenticatedProfilesByAuthUserSuccessOrWarningNotification } from 'src/actions/notifications';
import { graphAuthUserAuthenticationRequest } from 'src/sagas/authUsers';
import { integerArrayToStringArray } from 'src/utils/array';

function* facebookAuthUserConnectRequest(action) {
    const { payload } = action;
    const {
        identifier,
        authTransactionId,
        facebookAuthReq,
        instagramAuthReq,
        metaAuthReq
    } = payload;
    const profileRequirements = { ...facebookAuthReq, ...instagramAuthReq };
    const allRequirements = { ...profileRequirements, ...metaAuthReq };
    const { useCaseIds, authTransactionRequirementIds } = getUniqueUseCaseIdsWithAuthTransactionReqIds(allRequirements);
    try {
        const { response, serverError } = yield call(graphAuthUserAuthenticationRequest, useCaseIds);
        if (response) {
            const { authResponse } = response;
            const { accessToken } = authResponse;
            const params = {
                shortLiveAccessToken: accessToken,
                authTransactionRequirementIds: JSON.stringify(authTransactionRequirementIds),
                authTransactionId: _parseInt(authTransactionId)
            };
            const serverRequest = createServerRequest(params);
            const { response: authorizedResponse, serverError: authorizedServerError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/handle-graph-auth-user-connection-callback-url');
            if (authorizedResponse) {
                const { isAuthUserInAnotherSpace } = authorizedResponse.jsonData;
                if (isAuthUserInAnotherSpace) {
                    yield put(facebookAuthUserConnectSuccess(identifier, null, []));
                    yield put(modalsShowFacebookAuthUserInAnotherSpaceAuthTransactionWarning(identifier, authTransactionId, authTransactionRequirementIds, accessToken, Object.keys(profileRequirements), Object.keys(metaAuthReq), false));
                } else {
                    const {
                        authTransaction,
                        missingProfiles,
                        additionalProfiles,
                        connectedProfileIds,
                        connectedSpaceAdAccountIds
                    } = authorizedResponse.jsonData;
                    const parsedAuthTransaction = parseAuthTransaction(authTransaction);
                    const parsedAuthTransactionRequirements = parseAuthTransactionsRequirements(authTransaction);
                    yield put(facebookAuthUserConnectSuccess(
                        identifier,
                        parsedAuthTransaction,
                        parsedAuthTransactionRequirements,
                        {
                            missingProfiles,
                            additionalProfiles
                        }
                    ));
                    yield put(showAuthenticatedProfilesByAuthUserSuccessOrWarningNotification(integerArrayToStringArray(connectedProfileIds), Object.keys(profileRequirements), integerArrayToStringArray(connectedSpaceAdAccountIds), Object.keys(metaAuthReq)));
                }
            }
            if (authorizedServerError) {
                throw authorizedServerError;
            }
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(facebookAuthUserConnectError(identifier, applicationError.message));
    }
}

function* facebookAuthUserForceConnectRequest(action) {
    const { payload } = action;
    const {
        identifier,
        authTransactionId,
        authTransactionRequirementIds,
        shortLiveAccessToken,
        profileIds,
        adAccountIds,
    } = payload;

    const params = {
        shortLiveAccessToken,
        authTransactionRequirementIds: JSON.stringify(authTransactionRequirementIds),
        authTransactionId: _parseInt(authTransactionId),
        isAuthUserAllowedInAnotherSpace: true
    };
    try {
        const serverRequest = createServerRequest(params);
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/handle-graph-auth-user-connection-callback-url');
        if (response) {
            const {
                authTransaction,
                missingProfiles,
                additionalProfiles,
                connectedProfileIds,
                connectedSpaceAdAccountIds
            } = response.jsonData;

            const parsedAuthTransaction = parseAuthTransaction(authTransaction);
            const parsedAuthTransactionRequirements = parseAuthTransactionsRequirements(authTransaction);

            yield put(facebookAuthUserForceConnectSuccess(
                identifier,
                parsedAuthTransaction,
                parsedAuthTransactionRequirements,
                {
                    missingProfiles,
                    additionalProfiles
                }
            ));
            yield put(showAuthenticatedProfilesByAuthUserSuccessOrWarningNotification(integerArrayToStringArray(connectedProfileIds), profileIds, integerArrayToStringArray(connectedSpaceAdAccountIds), adAccountIds));
        }

        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(facebookAuthUserForceConnectError(identifier, applicationError.message));
    }
}

function* facebookAuthUserExternalConnectRequest(action) {
    const { payload } = action;
    const {
        hash,
        identifier,
        authTransactionId,
        facebookAuthReq,
        instagramAuthReq,
        metaAuthReq
    } = payload;
    const profileAuthReq = { ...facebookAuthReq, ...instagramAuthReq };
    const facebookAndInstagramAuthReqs = { ...profileAuthReq, ...metaAuthReq };
    const { useCaseIds, authTransactionRequirementIds } = getUniqueUseCaseIdsWithAuthTransactionReqIds(facebookAndInstagramAuthReqs);
    try {
        const { response, serverError } = yield call(graphAuthUserAuthenticationRequest, useCaseIds);
        if (response) {
            const { authResponse } = response;
            const { accessToken } = authResponse;
            const params = {
                hash,
                shortLiveAccessToken: accessToken,
                authTransactionRequirementIds: JSON.stringify(authTransactionRequirementIds),
                authTransactionId: _parseInt(authTransactionId)
            };
            const serverRequest = createServerRequest(params);
            const { response: authorizedResponse, serverError: authorizedServerError } = yield handleAuthorizedServerRequest(serverRequest, '/client-public/handle-external-graph-auth-user-connection-callback-url');

            if (authorizedResponse) {
                const { isAuthUserInAnotherSpace } = authorizedResponse.jsonData;
                if (isAuthUserInAnotherSpace) {
                    yield put(facebookAuthUserExternalConnectSuccess(identifier, null, []));
                    yield put(modalsShowFacebookAuthUserInAnotherSpaceAuthTransactionWarning(identifier, authTransactionId, authTransactionRequirementIds, accessToken, Object.keys(profileAuthReq), Object.keys(metaAuthReq), true));
                } else {
                    const {
                        authTransaction,
                        missingProfiles,
                        additionalProfiles,
                        connectedProfileIds,
                        connectedSpaceAdAccountIds
                    } = authorizedResponse.jsonData;
                    const parsedAuthTransaction = parseAuthTransaction(authTransaction);
                    const parsedAuthTransactionRequirements = parseAuthTransactionsRequirements(authTransaction);
                    yield put(facebookAuthUserExternalConnectSuccess(
                        identifier,
                        parsedAuthTransaction,
                        parsedAuthTransactionRequirements,
                        {
                            missingProfiles,
                            additionalProfiles
                        }
                    ));

                    yield put(showAuthenticatedProfilesByAuthUserSuccessOrWarningNotification(integerArrayToStringArray(connectedProfileIds), Object.keys(profileAuthReq), integerArrayToStringArray(connectedSpaceAdAccountIds), Object.keys(metaAuthReq)));
                }
            }
            if (authorizedServerError) {
                throw authorizedServerError;
            }
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(facebookAuthUserExternalConnectError(identifier, applicationError.message));
    }
}

function* facebookAuthUserExternalForceConnectRequest(action) {
    const { payload } = action;
    const {
        identifier,
        hash,
        authTransactionId,
        authTransactionRequirementIds,
        shortLiveAccessToken,
        profileIds,
        adAccountIds
    } = payload;
    const params = {
        hash,
        shortLiveAccessToken,
        authTransactionRequirementIds: JSON.stringify(authTransactionRequirementIds),
        authTransactionId: _parseInt(authTransactionId),
        isAuthUserAllowedInAnotherSpace: true
    };
    try {
        const serverRequest = createServerRequest(params);
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-public/handle-external-graph-auth-user-connection-callback-url');
        if (response) {
            const {
                authTransaction,
                missingProfiles,
                additionalProfiles,
                connectedProfileIds,
                connectedSpaceAdAccountIds
            } = response.jsonData;
            const parsedAuthTransaction = parseAuthTransaction(authTransaction);
            const parsedAuthTransactionRequirements = parseAuthTransactionsRequirements(authTransaction);

            yield put(facebookAuthUserExternalForceConnectSuccess(
                identifier,
                parsedAuthTransaction,
                parsedAuthTransactionRequirements,
                {
                    missingProfiles,
                    additionalProfiles
                }
            ));

            yield put(showAuthenticatedProfilesByAuthUserSuccessOrWarningNotification(integerArrayToStringArray(connectedProfileIds), profileIds, integerArrayToStringArray(connectedSpaceAdAccountIds), adAccountIds));
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(facebookAuthUserExternalForceConnectError(identifier, applicationError.message));
    }
}

export default function* graphAuthUsersSagas() {
    yield takeEvery(FACEBOOK_AUTH_USER_CONNECT_REQUEST, facebookAuthUserConnectRequest);
    yield takeEvery(FACEBOOK_AUTH_USER_FORCE_CONNECT_REQUEST, facebookAuthUserForceConnectRequest);
    yield takeEvery(FACEBOOK_AUTH_USER_EXTERNAL_CONNECT_REQUEST, facebookAuthUserExternalConnectRequest);
    yield takeEvery(FACEBOOK_AUTH_USER_EXTERNAL_FORCE_CONNECT_REQUEST, facebookAuthUserExternalForceConnectRequest);
}
