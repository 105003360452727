import {
    call,
    put,
    takeEvery
} from 'redux-saga/effects';
import {
    getUniqueUseCaseIdsWithAuthTransactionReqIds,
    handleAuthorizedServerRequest
} from 'src/sagas/utils';
import {
    LINKED_IN_AUTH_USER_CONNECT_REQUEST,
    LINKED_IN_AUTH_USER_EXTERNAL_CONNECT_REQUEST,
    LINKED_IN_AUTH_USER_EXTERNAL_FORCE_CONNECT_REQUEST,
    LINKED_IN_AUTH_USER_FORCE_CONNECT_REQUEST,
    linkedInAuthUserConnectError,
    linkedInAuthUserConnectSuccess,
    linkedInAuthUserExternalConnectError,
    linkedInAuthUserExternalConnectSuccess,
    linkedInAuthUserExternalForceConnectError,
    linkedInAuthUserExternalForceConnectSuccess,
    linkedInAuthUserForceConnectError,
    linkedInAuthUserForceConnectSuccess
} from 'src/actions/linkedInAuthUsers';
import {
    parseAuthTransaction,
    parseAuthTransactionsRequirements
} from 'src/parsers';
import createServerRequest from 'src/requestHandling/createServerRequest';
import { modalsShowLinkedInAuthUserInAnotherSpaceAuthTransactionWarning } from 'src/actions/overlays';
import { showAuthenticatedProfilesByAuthUserSuccessOrWarningNotification } from 'src/actions/notifications';
import { linkedInAuthUserAuthenticationRequest } from 'src/sagas/authUsers';

function* linkedInAuthUserConnectRequest(action) {
    const { payload } = action;
    const {
        identifier,
        authTransactionId,
        authTransactionRequirementsPerProfile
    } = payload;
    const { useCaseIds, authTransactionRequirementIds } = getUniqueUseCaseIdsWithAuthTransactionReqIds(authTransactionRequirementsPerProfile);
    try {
        const { response, serverError } = yield call(linkedInAuthUserAuthenticationRequest, useCaseIds);
        if (response) {
            const authorizedRequest = createServerRequest({
                code: response.code,
                authTransactionId,
                authTransactionRequirementIds: JSON.stringify(authTransactionRequirementIds)
            });
            const { response: authorizedResponse, serverError: authorizedServerError } = yield handleAuthorizedServerRequest(authorizedRequest, '/client-index/finish-linked-in-auth-user-connection-callback-url');
            if (authorizedResponse) {
                const { isAuthUserInAnotherSpace } = authorizedResponse.jsonData;
                if (isAuthUserInAnotherSpace) {
                    const {
                        accessToken,
                        refreshToken,
                        validUntil,
                        refreshTokenValidUntil,
                        platformUserId,
                        platformUserName
                    } = authorizedResponse.jsonData;
                    yield put(linkedInAuthUserConnectSuccess(identifier, null, []));
                    yield put(modalsShowLinkedInAuthUserInAnotherSpaceAuthTransactionWarning(identifier, authTransactionId, authTransactionRequirementIds, accessToken, refreshToken, validUntil, refreshTokenValidUntil, platformUserId, platformUserName, Object.keys(authTransactionRequirementsPerProfile), false));
                } else {
                    const {
                        authTransaction, connectedProfileIds
                    } = authorizedResponse.jsonData;
                    const parsedAuthTransaction = parseAuthTransaction(authTransaction);
                    const parsedAuthTransactionRequirements = parseAuthTransactionsRequirements(authTransaction);
                    yield put(linkedInAuthUserConnectSuccess(
                        identifier,
                        parsedAuthTransaction,
                        parsedAuthTransactionRequirements
                    ));
                    yield put(showAuthenticatedProfilesByAuthUserSuccessOrWarningNotification(connectedProfileIds.map((id) => `${id}`), Object.keys(authTransactionRequirementsPerProfile)));
                }
            }
            if (authorizedServerError) {
                throw authorizedServerError;
            }
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(linkedInAuthUserConnectError(identifier, applicationError.message));
    }
}

function* linkedInAuthUserForceConnectRequest(action) {
    const { payload } = action;
    const {
        identifier,
        authTransactionId,
        authTransactionRequirementIds,
        accessToken,
        refreshToken,
        validUntil,
        refreshTokenValidUntil,
        platformUserId,
        platformUserName,
        profileIds
    } = payload;
    const params = {
        authTransactionId,
        accessToken,
        validUntil,
        refreshToken,
        refreshTokenValidUntil,
        authTransactionRequirementIds: JSON.stringify(authTransactionRequirementIds),
        platformUserId,
        platformUserName
    };

    try {
        const serverRequest = createServerRequest(params);
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/handle-linked-in-auth-user-force-connection');
        if (response) {
            const {
                authTransaction, connectedProfileIds
            } = response.jsonData;
            const parsedAuthTransaction = parseAuthTransaction(authTransaction);
            const parsedAuthTransactionRequirements = parseAuthTransactionsRequirements(authTransaction);
            yield put(linkedInAuthUserForceConnectSuccess(
                identifier,
                parsedAuthTransaction,
                parsedAuthTransactionRequirements
            ));
            yield put(showAuthenticatedProfilesByAuthUserSuccessOrWarningNotification(connectedProfileIds.map((id) => `${id}`), profileIds));
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(linkedInAuthUserForceConnectError(identifier, applicationError.message));
    }
}

function* linkedInAuthUserExternalConnectRequest(action) {
    const { payload } = action;
    const {
        hash,
        identifier,
        authTransactionId,
        authTransactionRequirementsPerProfile
    } = payload;
    const { useCaseIds, authTransactionRequirementIds } = getUniqueUseCaseIdsWithAuthTransactionReqIds(authTransactionRequirementsPerProfile);
    try {
        const { response, serverError } = yield call(linkedInAuthUserAuthenticationRequest, useCaseIds);
        if (response) {
            const { code } = response;
            const authorizedRequest = createServerRequest({
                code,
                authTransactionId,
                authTransactionRequirementIds: JSON.stringify(authTransactionRequirementIds),
                hash
            });
            const { response: authorizedResponse, serverError: authorizedServerError } = yield handleAuthorizedServerRequest(authorizedRequest, '/client-public/finish-linked-in-external-auth-user-connection-callback-url');
            if (authorizedResponse) {
                const { isAuthUserInAnotherSpace } = authorizedResponse.jsonData;
                if (isAuthUserInAnotherSpace) {
                    const {
                        accessToken,
                        refreshToken,
                        validUntil,
                        refreshTokenValidUntil,
                        platformUserId,
                        platformUserName
                    } = authorizedResponse.jsonData;
                    yield put(linkedInAuthUserExternalConnectSuccess(identifier, null, [], [], null, []));
                    yield put(modalsShowLinkedInAuthUserInAnotherSpaceAuthTransactionWarning(identifier, authTransactionId, authTransactionRequirementIds, accessToken, refreshToken, validUntil, refreshTokenValidUntil, platformUserId, platformUserName, Object.keys(authTransactionRequirementsPerProfile), true));
                } else {
                    const { authTransaction, connectedProfileIds } = authorizedResponse.jsonData;
                    const parsedAuthTransaction = parseAuthTransaction(authTransaction);
                    const parsedAuthTransactionRequirements = parseAuthTransactionsRequirements(authTransaction);
                    yield put(linkedInAuthUserExternalConnectSuccess(
                        identifier,
                        parsedAuthTransaction,
                        parsedAuthTransactionRequirements
                    ));
                    yield put(showAuthenticatedProfilesByAuthUserSuccessOrWarningNotification(connectedProfileIds.map((id) => `${id}`), Object.keys(authTransactionRequirementsPerProfile)));
                }
            }
            if (authorizedServerError) {
                throw authorizedServerError;
            }
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(linkedInAuthUserExternalConnectError(identifier, applicationError.message));
    }
}

function* linkedInAuthUserExternalForceConnectRequest(action) {
    const { payload } = action;
    const {
        hash,
        identifier,
        authTransactionId,
        authTransactionRequirementIds,
        accessToken,
        refreshToken,
        validUntil,
        refreshTokenValidUntil,
        platformUserId,
        platformUserName,
        profileIds
    } = payload;
    const params = {
        hash,
        authTransactionId,
        accessToken,
        refreshToken,
        validUntil,
        refreshTokenValidUntil,
        authTransactionRequirementIds: JSON.stringify(authTransactionRequirementIds),
        platformUserId,
        platformUserName
    };

    try {
        const serverRequest = createServerRequest(params);
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-public/handle-external-linked-in-auth-user-force-connection');
        if (response) {
            const { authTransaction, connectedProfileIds } = response.jsonData;
            const parsedAuthTransaction = parseAuthTransaction(authTransaction);
            const parsedAuthTransactionRequirements = parseAuthTransactionsRequirements(authTransaction);

            yield put(linkedInAuthUserExternalForceConnectSuccess(
                identifier,
                parsedAuthTransaction,
                parsedAuthTransactionRequirements
            ));
            yield put(showAuthenticatedProfilesByAuthUserSuccessOrWarningNotification(connectedProfileIds.map((id) => `${id}`), profileIds));
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(linkedInAuthUserExternalForceConnectError(identifier, applicationError.message));
    }
}

export default function* linkedInAuthUsersSagas() {
    yield takeEvery(LINKED_IN_AUTH_USER_CONNECT_REQUEST, linkedInAuthUserConnectRequest);
    yield takeEvery(LINKED_IN_AUTH_USER_FORCE_CONNECT_REQUEST, linkedInAuthUserForceConnectRequest);
    yield takeEvery(LINKED_IN_AUTH_USER_EXTERNAL_CONNECT_REQUEST, linkedInAuthUserExternalConnectRequest);
    yield takeEvery(LINKED_IN_AUTH_USER_EXTERNAL_FORCE_CONNECT_REQUEST, linkedInAuthUserExternalForceConnectRequest);
}
