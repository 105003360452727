import { createSelector } from 'reselect';

export const getPlatformPermissions = (state) => state.entities.platformPermissions.byId;

export const selectPlatformPermissionsById = createSelector(
    [
        getPlatformPermissions,
        (_, id) => id
    ],
    (platformPermissions, id) => platformPermissions[id] || false
);

export const makeSelectPlatformPermissionsByIds = () => createSelector(
    [
        getPlatformPermissions,
        (_, ids) => ids
    ],
    (platformPermissions, ids) => ids.map((id) => platformPermissions[id])
);

export const selectScopesByPlatformPermissionsIds = createSelector(
    [
        getPlatformPermissions,
        (_, ids) => ids
    ],
    (platformPermissions, ids) => ids.map((id) => platformPermissions[id].scope)
);
