import * as customPropTypes from 'src/customPropTypes';
import React from 'react';

const Notification = ({ children }) => <div>{children}</div>;

Notification.propTypes = {
    children: customPropTypes.children.isRequired
};

export default Notification;
