import { createSelector } from 'reselect';

export const getBlobs = (state) => state.exports.blobs;
export const getIsReportPreviewPending = (state) => state.exports.isReportPreviewPending;
export const getMeta = (state) => state.exports.meta;

export const selectExportedFileByIndicator = createSelector(
    [
        getBlobs,
        (_, fileExportIdentifier) => fileExportIdentifier
    ],
    (blobs, fileExportIdentifier) => blobs[fileExportIdentifier] || false
);

export const selectMetaForExportedFileByIndicator = createSelector(
    [
        getMeta,
        (_, fileExportIdentifier) => fileExportIdentifier
    ],
    (meta, fileExportIdentifier) => meta[fileExportIdentifier] || false
);
