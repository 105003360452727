import { call, takeEvery } from 'redux-saga/effects';
import { PREPARE_FACEBOOK_SDK } from 'src/actions/facebookSDK';
import { loadScript } from 'src/sagas/utils';

// prepare facebook SDK
// courtesy of https://github.com/diegohaz/redux-saga-social-login/blob/master/src/store/social/sagas.js
const appendFbRoot = () => {
    const fbRoot = document.createElement('div');
    fbRoot.id = 'fb-root';
    document.body.appendChild(fbRoot);
};

function* prepareFacebookSDK(action) {
    const { payload } = action;
    const { options } = payload;
    try {
        yield call(appendFbRoot);
        yield call(loadScript, '//connect.facebook.net/en_US/sdk.js');
        yield call([window.FB, window.FB.init], options);
    } catch (e) {
        // Do nothing here, let the the concrete actions handle error cases for not loaded sdk
    }
}

export default function* facebookSDKSagas() {
    yield takeEvery(PREPARE_FACEBOOK_SDK, prepareFacebookSDK);
}
