import React, { PureComponent } from 'react';
import * as customPropTypes from 'src/customPropTypes';
import * as Notifications from 'src/components/notifications';
import _get from 'lodash/get';
import { connect } from 'react-redux';
import { hideNotification } from 'src/actions/notifications';
import PropTypes from 'prop-types';
import { selectNotifications } from 'src/selectors/notifications';
import { toast, ToastContainer, } from 'react-toastify';
import _omit from 'lodash/omit';
import 'react-toastify/dist/ReactToastify.css';
import styles from 'src/stylesheets/notificationHandler.scss';
import classNames from 'classnames';
import _has from 'lodash/has';

let stateHandler = {};

class NotificationHandler extends PureComponent {
    componentDidUpdate() {
        const { hideNotificationAction, notifications } = this.props;
        const notificationIds = notifications.map((notification) => notification.id);

        // Get all active notifications from react-notification-system
        // and remove all where uid is not found in the reducer

        const omitKeys = [];
        Object.keys(stateHandler).forEach((internalNotificationId) => {
            if (notificationIds.indexOf(internalNotificationId) < 0) {
                toast.dismiss(stateHandler[internalNotificationId]);
                omitKeys.push(internalNotificationId);
            }
        });

        if (omitKeys.length > 0) {
            stateHandler = _omit(stateHandler, omitKeys);
        }

        notifications.forEach((notification) => {
            const removeNotification = () => {
                hideNotificationAction(notification.id);
            };

            const toastifyOptions = {
                onClose: removeNotification,
                autoClose: notification.dismissible === false ? false : notification.autoDismiss * 1000,
                closeButton: false,
                className: classNames(styles.toast, { [styles.closeOnClick]: notification.dismissible }),
                closeOnClick: notification.dismissible
            };

            let content = null;

            if (notification.notificationType) {
                const ComponentToRender = _get(Notifications, notification.notificationType, null);
                if (ComponentToRender) {
                    content = <ComponentToRender {...notification.notificationProps} level={notification.level} hideNotification={removeNotification} />;
                } else {
                    throw new Error(`Unknown notification type: ${notification.notificationType}`);
                }
            } else {
                content = <Notifications.Default message={notification.message} level={notification.level} hideNotification={removeNotification} />;
            }

            if (!_has(stateHandler, notification.id)) {
                stateHandler[notification.id] = toast(content, toastifyOptions);
            }
        });
    }

    render() {
        return (
            <ToastContainer
              position="bottom-center"
              autoClose={5000}
              hideProgressBar
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable={false}
              pauseOnHover
              className={styles.container}
            />
        );
    }
}

NotificationHandler.propTypes = {
    hideNotificationAction: PropTypes.func.isRequired,
    notifications: customPropTypes.notifications.isRequired
};

function mapStateToProps(state) {
    return {
        notifications: selectNotifications(state)
    };
}

export default connect(mapStateToProps, { hideNotificationAction: hideNotification })(NotificationHandler);
