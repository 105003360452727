import Feedback from 'src/components/feedback/Feedback';
import Notification from 'src/components/notifications/layout/Notification';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router';
import { dataPushTasksRoute } from 'src/routePaths';

const DataPushTaskDryRunSuccessAndFailedNotification = (props) => {
    const { dataPushTaskId, level } = props;
    const content = level === 'success' ? 'Dry run successfully finished.' : 'Dry run failed. ';
    const button = <Link to={`${dataPushTasksRoute}/${dataPushTaskId}`}>Visit data push task</Link>;
    return (
        <Notification>
            <Feedback
              type={level}
              responsive={false}
              content={content}
              button={button}
            />
        </Notification>
    );
};

DataPushTaskDryRunSuccessAndFailedNotification.propTypes = {
    dataPushTaskId: PropTypes.string.isRequired,
    level: PropTypes.string.isRequired
};

export default DataPushTaskDryRunSuccessAndFailedNotification;
