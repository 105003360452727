import { combineReducers } from 'redux';
import { GET_AUTH_TRANSACTION_BY_HASH_SUCCESS } from 'src/actions/authTransactions';
import { USER_LOGGED_IN } from 'src/actions/loggedInUser';
import { EXTERNAL_NETWORK_AUTHENTICATION_BOOTSTRAP_SUCCESS } from 'src/actions/socialNetworkProfileAuthentication';
import { socialNetworkAdAccountAuthenticationBootstrapSuccess } from 'src/actions/socialNetworkAdAccountAuthentication';

const allIds = (state = [], action) => {
    const { type, payload } = action;
    switch (type) {
        case USER_LOGGED_IN: {
            const { accountAuthenticationEntities } = payload;
            return [...state, ...Object.keys(accountAuthenticationEntities.platformPermissions)];
        }
        case GET_AUTH_TRANSACTION_BY_HASH_SUCCESS:
        case EXTERNAL_NETWORK_AUTHENTICATION_BOOTSTRAP_SUCCESS:
        case socialNetworkAdAccountAuthenticationBootstrapSuccess.type: {
            const { platformPermissions } = payload;
            return [...state, ...Object.keys(platformPermissions)];
        }
        default:
            return state;
    }
};

const byId = (state = {}, action) => {
    const { payload, type } = action;
    switch (type) {
        case USER_LOGGED_IN: {
            const { accountAuthenticationEntities } = payload;
            return Object.assign({}, state, accountAuthenticationEntities.platformPermissions);
        }
        case GET_AUTH_TRANSACTION_BY_HASH_SUCCESS:
        case EXTERNAL_NETWORK_AUTHENTICATION_BOOTSTRAP_SUCCESS:
        case socialNetworkAdAccountAuthenticationBootstrapSuccess.type: {
            const { platformPermissions } = payload;
            return Object.assign({}, state, platformPermissions);
        }
        default:
            return state;
    }
};

export const platformPermissions = combineReducers({
    allIds,
    byId
});
