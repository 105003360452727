import React, { Component } from 'react';
import { selectExportedFileByIndicator, selectMetaForExportedFileByIndicator } from 'src/selectors/exports';
import * as exportsAction from 'src/actions/exports';
import _get from 'lodash/get';
import _has from 'lodash/has';
import { connect } from 'react-redux';
import Feedback from 'src/components/feedback/Feedback';
import FeedbackLinkGroup from 'src/components/feedback/FeedbackLinkGroup';
import FeedbackLinkPlain from 'src/components/feedback/FeedbackLinkPlain';
import Notification from 'src/components/notifications/layout/Notification';
import PropTypes from 'prop-types';
import { reportError } from 'src/utils/reportError';

// From https://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
};

const generateErrorMessage = (fileName, format, error = null) => {
    let message = 'Please try again in a few moments and let our support team know at <a href="mailto:support@facelift-bbt.com">support@facelift-bbt.com</a> if this problem remains.';
    if (error && _has(error, 'message')) {
        message = error.message;
    }
    return `Exporting "${fileName}.${format}" failed. ${message}`;
};

export class FileExportSuccessOrError extends Component {
    componentWillUnmount() {
        const { exportFileDeleteAction, fileExportIdentifier } = this.props;
        exportFileDeleteAction(fileExportIdentifier);
    }

    render() {
        const {
            exportedFile, fileName, hideNotification, format
        } = this.props;
        const { data, isLoading, error } = exportedFile;

        let processingError = false;
        let type = null;
        let message = '';
        let linkProps = {};

        if (isLoading) {
            type = 'info';
            message = `Preparing "${fileName}" for download...`;
        } else if (data) {
            try {
                // We have to build a Blob object from our base64 string again
                // to not run into max length restrictions of the href parameter using a string
                // TODO: We could also store content type and data separately in our store directly to avoid string cutting here
                const contentType = data.blob.substring(5, data.blob.indexOf(';'));
                const blobDataWithoutContentType = data.blob.substr(data.blob.indexOf(',') + 1);
                const blob = b64toBlob(blobDataWithoutContentType, contentType);

                // check for ms handling
                if (_get(window, 'navigator.msSaveOrOpenBlob', false)) {
                    linkProps = {
                        href: '#',
                        onClick: (event) => {
                            window.navigator.msSaveOrOpenBlob(blob, data.fileName);
                            hideNotification();
                            event.preventDefault();
                        }
                    };
                } else {
                    linkProps = {
                        href: URL.createObjectURL(blob),
                        download: data.fileName,
                        onClick: hideNotification
                    };
                }

                type = 'success';
                message = `"${data.fileName}" is now ready for download.`;
            } catch (e) {
                const { meta } = this.props;
                type = 'error';
                message = generateErrorMessage(fileName, format);
                reportError(e, meta);
                processingError = true;
            }
        } else if (error) {
            type = 'error';
            message = generateErrorMessage(fileName, format, error);
        }
        return (
            <Notification>
                <Feedback
                  allowHTML
                  type={type}
                  content={message}
                  responsive={false}
                  link={(
                      <FeedbackLinkGroup>
                          <FeedbackLinkPlain disabled={isLoading || !!error || processingError} {...linkProps}>Download</FeedbackLinkPlain>
                          <FeedbackLinkPlain onClick={hideNotification}>Cancel</FeedbackLinkPlain>
                      </FeedbackLinkGroup>
                  )}
                />
            </Notification>
        );
    }
}

FileExportSuccessOrError.propTypes = {
    exportedFile: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]).isRequired,
    meta: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]).isRequired,
    exportFileDeleteAction: PropTypes.func.isRequired,
    fileExportIdentifier: PropTypes.string.isRequired,
    fileName: PropTypes.string.isRequired,
    format: PropTypes.string.isRequired,
    hideNotification: PropTypes.func
};

const makeMapStateToProps = (state, props) => ({
    exportedFile: selectExportedFileByIndicator(state, props.fileExportIdentifier),
    meta: selectMetaForExportedFileByIndicator(state, props.fileExportIdentifier)
});

export default connect(makeMapStateToProps, {
    exportFileDeleteAction: exportsAction.exportFileDelete
})(FileExportSuccessOrError);
