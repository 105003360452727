import Notification from 'src/components/notifications/layout/Notification';
import Feedback from 'src/components/feedback/Feedback';
import FeedbackLink from 'src/components/feedback/FeedbackLink';
import PropTypes from 'prop-types';
import React from 'react';

const UnfulfilledAccountUseCases = (props) => {
    const { showGoToAuthenticationsButton } = props;
    const type = 'warning';
    return (
        <Notification>
            {
                showGoToAuthenticationsButton
                && (
                    <Feedback
                      type={type}
                      responsive={false}
                      content="Some of these use cases are unfulfilled. Authenticate your accounts now to start receiving private statistics data for this account."
                      link={<FeedbackLink to="/authentications">Go to Authentications</FeedbackLink>}
                    />
                )
            }
            {
                !showGoToAuthenticationsButton
                && (
                    <Feedback
                      type={type}
                      responsive={false}
                      content="Some of the use cases are unfulfilled, you can fulfil these use-cases later in the Authentications section."
                    />
                )
            }
        </Notification>
    );
};

UnfulfilledAccountUseCases.propTypes = {
    showGoToAuthenticationsButton: PropTypes.bool.isRequired
};

export default UnfulfilledAccountUseCases;
