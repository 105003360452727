import _uniq from 'lodash/uniq';

export const getUniquePlatformPermissionIds = (useCases) => {
    let allPlatformPermissionIds = [];
    useCases.forEach((useCase) => {
        const { platformPermissionIds } = useCase;
        allPlatformPermissionIds = allPlatformPermissionIds.concat(platformPermissionIds);
    });
    return _uniq(allPlatformPermissionIds);
};
