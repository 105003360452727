import { createSelector } from 'reselect';

const getNotificationsFromStore = (state) => state.notifications;

export const selectNotifications = createSelector(
    [
        getNotificationsFromStore
    ],
    (notificationsFromStore) => {
        const notifications = [];
        Object.keys(notificationsFromStore).forEach((notificationId) => {
            notifications.push(notificationsFromStore[notificationId]);
        });
        return notifications;
    }
);
