import moment from 'moment-timezone';
import { getLocalString } from 'src/components/Date';
import { publicAppUrl } from 'src/config';
import _memoize from 'memoize-one';
import _uniq from 'lodash/uniq';

export const isAuthTransactionOpen = (isOpen, validUntil) => {
    const validUntilDate = moment.tz(validUntil, 'UTC').unix();
    const now = moment.utc().unix();
    return isOpen === true && now < validUntilDate;
};

export const getAuthTransactionIconFromState = (isAuthOpen, isInProgress, isFulfilled) => {
    if (isAuthOpen) {
        return isInProgress ? 'in-progress' : 'pending';
    }
    return isFulfilled ? 'success' : 'close';
};

export const isAuthTransactionExpired = (validUntil,) => {
    const validUntilDate = moment.tz(validUntil, 'UTC').unix();
    const now = moment.utc().unix();
    return now > validUntilDate;
};

export const getAuthTransactionLabelFromStateNew = (isOpen, validUntil, isInProgress, isFulfilled) => {
    const isExpired = isAuthTransactionExpired(validUntil);

    if (isOpen === true && !isExpired) {
        return isInProgress ? 'In-progress' : 'Pending';
    }

    if (isFulfilled) {
        return 'Fulfilled';
    }
    return isExpired ? 'Expired' : 'Unfulfilled';
};

export const getFieldName = (accountType, accountId, useCaseId) => `${accountType}_${accountId}_${useCaseId}`;

export const getAllAccountsFieldNames = (accounts, useCases, accountType) => {
    const fieldNames = [];
    accounts.forEach((account) => {
        useCases.forEach((useCase) => {
            fieldNames.push(getFieldName(accountType, account.id, useCase.id));
        });
    });
    return fieldNames;
};

export const getAuthTransactionName = (accountOptions) => {
    const stringDate = getLocalString(moment().format('YYYY-MM-DD HH:mm:ss'), 'date', accountOptions);
    return `Auth - ${stringDate}`;
};

export const getShareLink = (hash) => `${publicAppUrl}/external-authentication?hash=${hash}`;

export const platformTypeOrder = ['facebook', 'instagram', 'meta', 'twitter', 'youtube', 'linkedIn', 'snapchatShow', 'tiktok', 'threads'];

export const getAccountIdFromAuthTransactionRequirements = _memoize((authTransactionRequirements, accountType) => _uniq(authTransactionRequirements.map((authTransactionRequirement) => (accountType === 'profiles' ? authTransactionRequirement.profileId : authTransactionRequirement.adAccountId))));
