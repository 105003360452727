import {
    CREATE_AUTH_TRANSACTION_SUCCESS,
    CREATE_BASIC_AUTH_TRANSACTION_SUCCESS,
    CREATE_ONBOARDING_BASIC_AUTH_TRANSACTION_SUCCESS,
    GET_AUTH_TRANSACTION_BY_HASH_SUCCESS,
    loadAuthTransactionsSuccess
} from 'src/actions/authTransactions';
import {
    FACEBOOK_AUTH_USER_CONNECT_SUCCESS,
    FACEBOOK_AUTH_USER_EXTERNAL_CONNECT_SUCCESS,
    FACEBOOK_AUTH_USER_EXTERNAL_FORCE_CONNECT_SUCCESS,
    FACEBOOK_AUTH_USER_FORCE_CONNECT_SUCCESS
} from 'src/actions/graphAuthUsers';
import {
    LINKED_IN_AUTH_USER_CONNECT_SUCCESS,
    LINKED_IN_AUTH_USER_EXTERNAL_CONNECT_SUCCESS,
    LINKED_IN_AUTH_USER_EXTERNAL_FORCE_CONNECT_SUCCESS,
    LINKED_IN_AUTH_USER_FORCE_CONNECT_SUCCESS,
} from 'src/actions/linkedInAuthUsers';
import {
    SNAPCHAT_AUTH_USER_CONNECT_SUCCESS,
    SNAPCHAT_AUTH_USER_EXTERNAL_CONNECT_SUCCESS,
    SNAPCHAT_AUTH_USER_EXTERNAL_FORCE_CONNECT_SUCCESS,
    SNAPCHAT_AUTH_USER_FORCE_CONNECT_SUCCESS
} from 'src/actions/snapchatAuthUsers';
import {
    TWITTER_AUTH_USER_CONNECT_SUCCESS,
    TWITTER_AUTH_USER_EXTERNAL_CONNECT_SUCCESS
} from 'src/actions/twitterAuthUsers';
import {
    YOUTUBE_AUTH_USER_CONNECT_SUCCESS,
    YOUTUBE_AUTH_USER_EXTERNAL_CONNECT_SUCCESS
} from 'src/actions/youtubeAuthUsers';
import {
    TIKTOK_AUTH_USER_CONNECT_SUCCESS,
    TIKTOK_AUTH_USER_EXTERNAL_CONNECT_SUCCESS
} from 'src/actions/tiktokAuthUsers';
import { combineReducers } from 'redux';

const allIds = (state = [], action) => {
    const { type, payload } = action;
    switch (type) {
        case loadAuthTransactionsSuccess.type: {
            const { profileAuthTransactionRequirements, adAccountAuthTransactionRequirements } = payload;
            return [...Object.keys(profileAuthTransactionRequirements), ...Object.keys(adAccountAuthTransactionRequirements)];
        }
        case CREATE_AUTH_TRANSACTION_SUCCESS:
        case CREATE_BASIC_AUTH_TRANSACTION_SUCCESS:
        case GET_AUTH_TRANSACTION_BY_HASH_SUCCESS:
        case CREATE_ONBOARDING_BASIC_AUTH_TRANSACTION_SUCCESS: {
            const { authTransactionRequirements } = payload;
            const { profileAuthTransactionRequirements, adAccountAuthTransactionRequirements } = authTransactionRequirements;
            return [...state, ...Object.keys(profileAuthTransactionRequirements), ...Object.keys(adAccountAuthTransactionRequirements)];
        }
        default:
            return state;
    }
};

const profileAuthTransactionRequirements = (state = {}, action) => {
    const { payload, type } = action;
    switch (type) {
        case CREATE_AUTH_TRANSACTION_SUCCESS:
        case CREATE_BASIC_AUTH_TRANSACTION_SUCCESS:
        case CREATE_ONBOARDING_BASIC_AUTH_TRANSACTION_SUCCESS:
        case FACEBOOK_AUTH_USER_CONNECT_SUCCESS:
        case FACEBOOK_AUTH_USER_FORCE_CONNECT_SUCCESS:
        case FACEBOOK_AUTH_USER_EXTERNAL_CONNECT_SUCCESS:
        case FACEBOOK_AUTH_USER_EXTERNAL_FORCE_CONNECT_SUCCESS:
        case GET_AUTH_TRANSACTION_BY_HASH_SUCCESS:
        case LINKED_IN_AUTH_USER_CONNECT_SUCCESS:
        case LINKED_IN_AUTH_USER_EXTERNAL_CONNECT_SUCCESS:
        case LINKED_IN_AUTH_USER_EXTERNAL_FORCE_CONNECT_SUCCESS:
        case LINKED_IN_AUTH_USER_FORCE_CONNECT_SUCCESS:
        case TWITTER_AUTH_USER_CONNECT_SUCCESS:
        case TWITTER_AUTH_USER_EXTERNAL_CONNECT_SUCCESS:
        case YOUTUBE_AUTH_USER_CONNECT_SUCCESS:
        case YOUTUBE_AUTH_USER_EXTERNAL_CONNECT_SUCCESS:
        case SNAPCHAT_AUTH_USER_EXTERNAL_CONNECT_SUCCESS:
        case SNAPCHAT_AUTH_USER_CONNECT_SUCCESS:
        case SNAPCHAT_AUTH_USER_FORCE_CONNECT_SUCCESS:
        case SNAPCHAT_AUTH_USER_EXTERNAL_FORCE_CONNECT_SUCCESS:
        case TIKTOK_AUTH_USER_EXTERNAL_CONNECT_SUCCESS:
        case TIKTOK_AUTH_USER_CONNECT_SUCCESS: {
            const { authTransactionRequirements } = payload;
            return Object.assign({}, state, authTransactionRequirements.profileAuthTransactionRequirements);
        }
        case loadAuthTransactionsSuccess.type: {
            return Object.assign({}, state, payload.profileAuthTransactionRequirements);
        }
        default:
            return state;
    }
};

const adAccountAuthTransactionRequirements = (state = {}, action) => {
    const { payload, type } = action;
    switch (type) {
        case CREATE_AUTH_TRANSACTION_SUCCESS:
        case CREATE_BASIC_AUTH_TRANSACTION_SUCCESS:
        case CREATE_ONBOARDING_BASIC_AUTH_TRANSACTION_SUCCESS:
        case FACEBOOK_AUTH_USER_CONNECT_SUCCESS:
        case FACEBOOK_AUTH_USER_FORCE_CONNECT_SUCCESS:
        case FACEBOOK_AUTH_USER_EXTERNAL_CONNECT_SUCCESS:
        case FACEBOOK_AUTH_USER_EXTERNAL_FORCE_CONNECT_SUCCESS:
        case GET_AUTH_TRANSACTION_BY_HASH_SUCCESS: {
            const { authTransactionRequirements } = payload;
            return Object.assign({}, state, authTransactionRequirements.adAccountAuthTransactionRequirements);
        }
        case loadAuthTransactionsSuccess.type: {
            return Object.assign({}, state, payload.adAccountAuthTransactionRequirements);
        }
        default:
            return state;
    }
};

export const authTransactionRequirements = combineReducers({
    allIds,
    profileAuthTransactionRequirements,
    adAccountAuthTransactionRequirements
});
