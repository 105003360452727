import {
    call,
    put,
    takeEvery
} from 'redux-saga/effects';
import createServerRequest from 'src/requestHandling/createServerRequest';
import {
    getUniqueUseCaseIdsWithAuthTransactionReqIds,
    handleAuthorizedServerRequest
} from 'src/sagas/utils';
import { parseAuthTransaction, parseAuthTransactionsRequirements } from 'src/parsers';
import {
    YOUTUBE_AUTH_USER_CONNECT_REQUEST,
    YOUTUBE_AUTH_USER_EXTERNAL_CONNECT_REQUEST,
    youtubeAuthUserConnectError,
    youtubeAuthUserConnectSuccess,
    youtubeAuthUserExternalConnectError,
    youtubeAuthUserExternalConnectSuccess
} from 'src/actions/youtubeAuthUsers';
import { showAuthenticatedProfilesByAuthUserSuccessOrWarningNotification } from 'src/actions/notifications';
import { youtubeAuthUserAuthenticationRequest } from 'src/sagas/authUsers';

function* youtubeAuthUserConnectRequest(action) {
    const { payload } = action;
    const {
        identifier,
        authTransactionId,
        authTransactionRequirements,
        profile
    } = payload;

    const authTransactionRequirementsByProfile = { [profile.id]: authTransactionRequirements };
    const { useCaseIds, authTransactionRequirementIds } = getUniqueUseCaseIdsWithAuthTransactionReqIds(authTransactionRequirementsByProfile);
    try {
        const { response, serverError } = yield call(youtubeAuthUserAuthenticationRequest, useCaseIds);
        if (response) {
            const { code } = response;
            const authorizedServerRequest = createServerRequest({
                code,
                authTransactionId,
                authTransactionRequirementIds: JSON.stringify(authTransactionRequirementIds)
            });
            const { response: authorizedResponse, serverError: authorizedServerError } = yield handleAuthorizedServerRequest(authorizedServerRequest, '/client-index/finish-youtube-auth-user-connection-callback-url');
            if (authorizedResponse) {
                const {
                    authTransaction,
                    connectedProfileIds
                } = authorizedResponse.jsonData;
                const parsedAuthTransaction = parseAuthTransaction(authTransaction);
                const parsedAuthTransactionRequirements = parseAuthTransactionsRequirements(authTransaction);
                yield put(youtubeAuthUserConnectSuccess(
                    identifier,
                    parsedAuthTransaction,
                    parsedAuthTransactionRequirements
                ));
                yield put(showAuthenticatedProfilesByAuthUserSuccessOrWarningNotification(connectedProfileIds.map((id) => `${id}`), [profile.id]));
            }
            if (authorizedServerError) {
                throw authorizedServerError;
            }
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(youtubeAuthUserConnectError(identifier, applicationError.message));
    }
}

function* youtubeAuthUserExternalConnectRequest(action) {
    const { payload } = action;
    const {
        hash,
        identifier,
        authTransactionId,
        authTransactionRequirements,
        profile
    } = payload;

    const authTransactionRequirementsByProfile = { [profile.id]: authTransactionRequirements };
    const { useCaseIds, authTransactionRequirementIds } = getUniqueUseCaseIdsWithAuthTransactionReqIds(authTransactionRequirementsByProfile);
    try {
        const { response, serverError } = yield call(youtubeAuthUserAuthenticationRequest, useCaseIds);
        if (response) {
            const { code } = response;
            const authorizedServerRequest = createServerRequest({
                code,
                authTransactionId,
                authTransactionRequirementIds: JSON.stringify(authTransactionRequirementIds),
                hash
            });
            const { response: authorizedResponse, serverError: authorizedServerError } = yield handleAuthorizedServerRequest(authorizedServerRequest, '/client-public/finish-youtube-external-auth-user-connection-callback-url');
            if (authorizedResponse) {
                const { authTransaction, connectedProfileIds } = authorizedResponse.jsonData;
                const parsedAuthTransaction = parseAuthTransaction(authTransaction);
                const parsedAuthTransactionRequirements = parseAuthTransactionsRequirements(authTransaction);
                yield put(youtubeAuthUserExternalConnectSuccess(
                    identifier,
                    parsedAuthTransaction,
                    parsedAuthTransactionRequirements
                ));
                yield put(showAuthenticatedProfilesByAuthUserSuccessOrWarningNotification(connectedProfileIds.map((id) => `${id}`), [profile.id]));
            }
            if (authorizedServerError) {
                throw authorizedServerError;
            }
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(youtubeAuthUserExternalConnectError(identifier, applicationError.message));
    }
}

export default function* youtubeAuthUsersSagas() {
    yield takeEvery(YOUTUBE_AUTH_USER_CONNECT_REQUEST, youtubeAuthUserConnectRequest);
    yield takeEvery(YOUTUBE_AUTH_USER_EXTERNAL_CONNECT_REQUEST, youtubeAuthUserExternalConnectRequest);
}
